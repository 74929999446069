import { Button, H1 } from '@/common/ui'
import useTradeModalStore from '@/trading/tradeModalStore'

type Props = {
    followers: number
    followersGoal: number
    targetCompaniesAsxCodes: string[]
    className?: string
}

export function TakeActionBox({ followers, followersGoal = 100, targetCompaniesAsxCodes, className }: Props) {
    console.log('targetCompaniesAsxCodes', targetCompaniesAsxCodes)
    console.log('followers', followers)

    const { setSecurityCodes, setOrderAction } = useTradeModalStore()

    return (
        <aside className={`bg-primary w-full rounded-md p-20 text-white flex flex-col items-center ${className}`}>
            <H1 className="text-center">Take action</H1>
            <p className="text-center">
                Goal: Our target is to engage a minimum of {followersGoal} shareholders for each company, allowing us to
                draft resolutions before their AGMs. The greater the number of shareholders, the stronger our influence!
            </p>
            <p className="text-xs text-center mt-8 text-balance">
                The ASX sets an initial minimum buy-in of $500 for any company. The first time you buy shares in any
                company, you'll need to transfer in $500 + brokerage fees.
            </p>
            <p className="text-xs text-center mt-2">You own these shares outright until you sell them.</p>
            <Button
                onClick={() => {
                    setSecurityCodes(targetCompaniesAsxCodes)
                    setOrderAction('BUY')
                }}
                className="mt-8"
                variant="secondary"
            >
                Buy shares
            </Button>
        </aside>
    )
}
