import { AuthUser } from 'aws-amplify/auth'
import { create } from 'zustand'

interface State {
    isForgotPassword: boolean
    username: string
    authDetails: AuthUser | null
}
interface AuthState extends State {
    setIsForgotPassword: (isForgotPassword: boolean) => void
    setUsername: (username: string) => void
    setAuthDetails: (authDetails: AuthUser) => void
}

const initialState: State = {
    isForgotPassword: false,
    username: '',
    authDetails: null,
}

const useAuthStore = create<AuthState>((set) => ({
    ...initialState,
    setIsForgotPassword: (isForgotPassword) => set(() => ({ isForgotPassword })),
    setUsername: (username) => set(() => ({ username })),
    setAuthDetails: (authDetails) => set(() => ({ authDetails })),
}))

export default useAuthStore
