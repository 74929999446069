import { API_ROUTES } from '@/common/constants'
import { app } from '../common/api/apiClient'
import { insertStringVariables } from '@/common/utils/utils'
import { CampaignT } from '@/common/types'
import { CampaignDetails } from './CampaignDetailsPage'
import { AgencyAgreementT } from './types'

export const getCampaignsList = async () => {
    const res = await app.get(API_ROUTES.campaignsList)
    return res.data as { campaigns: CampaignT[] }
}

export const getCampaignDetails = async (webflowId: string) => {
    const res = await app.get<CampaignDetails>(`${insertStringVariables(API_ROUTES.campaignDetails, webflowId)}`)
    return res.data
}

export const getCampaignSubscriptions = async () => {
    const res = await app.get<{ subscriptions: Record<string, boolean> }>(API_ROUTES.campaignSubscriptions)
    return res.data
}

export const submitAgencyAgreement = async (agencyAgreement: AgencyAgreementT) => {
    await app.post<AgencyAgreementT>(API_ROUTES.agencyAgreement, agencyAgreement)
}

export const getAgencyAgreement = async () => {
    const res = await app.get<{ stockCodes: string[] }>(API_ROUTES.agencyAgreement)
    return res.data
}

export const campaignSubscribe = async (campaignId: string) => {
    const res = await app.post<{ campaignId: string }>(
        `${insertStringVariables(API_ROUTES.campaignSubscribe, campaignId)}`,
    )
    return res.data
}

export const campaignUnsubscribe = async (campaignId: string) => {
    const res = await app.post<{ campaignId: string }>(
        `${insertStringVariables(API_ROUTES.campaignUnsubscribe, campaignId)}`,
    )
    return res.data
}

export const campaignPledge = async (campaignId: string, securityCode: string) => {
    await app.post<void>(`${insertStringVariables(API_ROUTES.campaignPledge, campaignId, securityCode)}`)
}

export const getPledgeBySecurityCode = async (campaignId: string, securityCode: string) => {
    const res = await app.get<number>(`${insertStringVariables(API_ROUTES.campaignPledge, campaignId, securityCode)}`)
    return res.data
}

export const getAgencyAgreementsBySecurityCode = async (campaignId: string, securityCode: string) => {
    const res = await app.get<{ count: number }>(
        `${insertStringVariables(API_ROUTES.agencyAgreements, campaignId, securityCode)}`,
    )
    return res.data.count
}
