import { Dialog, DialogContent } from '@/common/ui/dialog'
import { Spinner } from '@/common/ui/spinner'
import { cn } from '@/common/utils/utils'
import { useEffect, useState } from 'react'
import AccountDetailsForm from './AccountDetailsForm'
import WithdrawForm from './WithdrawForm'
import WithdrawAckDialog from './WithdrawAckDialog'
import { useFetchAccountDetails, useSaveAccountDetails, useSaveWithdrawRequest } from './walletQueries'
import { AccountDetails, AccountDetailsFormInput } from './types'
import { analytics } from '@/common/analytics/analytics'

type Props = {
    showWithdrawDialog: boolean
    onClose: () => void
    availableBalance: number
}

enum Steps {
    ACCOUNT_DETAILS,
    WITHDRAW,
    WITHDRAW_SUCCESS,
}

export default function WithdrawDialogContainer(props: Props) {
    const [accountDetails, setAccountDetails] = useState<AccountDetails | undefined>(undefined)
    const [savingAccountDetails, setSavingAccountDetails] = useState<boolean>(false)
    const [savingWithdrawRequest, setSavingWithdrawRequest] = useState<boolean>(false)
    const [currentStep, setCurrentStep] = useState<Steps | null>(null)
    const [lastRequestedAmount, setLastRequestedAmount] = useState<number>(0)

    const {
        data: account,
        isLoading: fetchingAccountDetails,
        refetch: reFetchAccountDetails,
    } = useFetchAccountDetails()
    const saveAccountDetails = useSaveAccountDetails()
    const saveWithdrawRequest = useSaveWithdrawRequest()

    useEffect(() => {
        setAccountDetails(account) // TODO: do we need this ?
        accountDetails ? setCurrentStep(Steps.WITHDRAW) : setCurrentStep(Steps.ACCOUNT_DETAILS)
    }, [account, accountDetails])

    const onAccDetailsSave = (accountDetailsInput: AccountDetailsFormInput) => {
        const { bankAccountName, bsb, bankAccountNumber } = accountDetailsInput
        const updatedAccount = {
            bankAccountName,
            bsb,
            bankAccountNumber,
            ...(accountDetails && {
                // if account already existing, attach remaining attributes as it as
                createdAt: accountDetails.createdAt,
                updatedAt: accountDetails.updatedAt,
                version: accountDetails.version,
                userId: accountDetails.userId,
            }),
        }
        setSavingAccountDetails(true)
        saveAccountDetails.mutate(updatedAccount, {
            onSuccess: async () => {
                await reFetchAccountDetails()
                setCurrentStep(Steps.WITHDRAW)
            },
            onSettled: () => {
                setSavingAccountDetails(false)
            },
        })
        analytics.track('BankAccountDetails Saved')
    }

    const onWithdrawRequest = (amount: number) => {
        const requestId = crypto.randomUUID()
        setSavingWithdrawRequest(true)
        saveWithdrawRequest.mutate(
            { amount, requestId },
            {
                onSuccess: () => {
                    setCurrentStep(Steps.WITHDRAW_SUCCESS)
                },
                onSettled: () => {
                    setSavingWithdrawRequest(false)
                    setLastRequestedAmount(amount)
                },
            },
        )
        analytics.track('WithdrawRequest Made')
    }

    const loader = (
        <div className={cn('flex items-center justify-center')}>
            <Spinner size="lg" />
        </div>
    )

    return (
        <Dialog open={true}>
            <DialogContent
                onClose={props.onClose}
                className="w-[90vw] px-10 py-14 max-h-[90vh] max-w-[600px] overflow-y-auto"
            >
                {fetchingAccountDetails ? (
                    loader
                ) : (
                    <>
                        {/* TODO: move to a factory function */}
                        {currentStep === Steps.ACCOUNT_DETAILS && (
                            <AccountDetailsForm
                                onSave={onAccDetailsSave}
                                accountDetails={accountDetails}
                                savingAccountDetails={savingAccountDetails}
                                handleBack={accountDetails ? () => setCurrentStep(Steps.WITHDRAW) : props.onClose}
                            />
                        )}
                        {currentStep === Steps.WITHDRAW && (
                            <WithdrawForm
                                accountDetails={accountDetails!}
                                handleBack={() => setCurrentStep(Steps.ACCOUNT_DETAILS)}
                                handleEdit={() => {
                                    setCurrentStep(Steps.ACCOUNT_DETAILS)
                                }}
                                savingWithdrawRequest={savingWithdrawRequest}
                                onWithdrawRequest={onWithdrawRequest}
                                availableBalance={props.availableBalance}
                            />
                        )}
                        {currentStep === Steps.WITHDRAW_SUCCESS && (
                            <WithdrawAckDialog
                                accountDetails={accountDetails!}
                                amount={lastRequestedAmount}
                                onClose={props.onClose}
                            />
                        )}
                    </>
                )}
            </DialogContent>
        </Dialog>
    )
}
