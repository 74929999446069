import { Button, H2 } from '@/common/ui'
import ActivistInvestingIllustration from './activistInvestingIllustration.webp'
import { cn } from '@/common/utils/utils'
import { analytics } from '@/common/analytics/analytics'

export default function Banner() {
    const trackClick = () => {
        analytics.track('ActivistInvestingLearnMoreButton Clicked')
    }

    return (
        <div
            className={cn(
                'grid grid-cols-[1fr_2fr] w-full my-4 p-4 gap-4 rounded-lg bg-[#C9F691]',
                'lg:grid-cols-[260px_1fr_auto]',
            )}
        >
            <img src={ActivistInvestingIllustration} className="row-span-2 lg:row-span-1 lg:mb-[-16px]" />
            <div className="lg:self-center">
                <H2 className="text-2xl sm:text-3xl">How does activist investing work?</H2>
                <p className="text-sm lg:text-base">
                    Shareholder activism is a powerful tool that activists have been using for decades to create change
                    in big companies. Now with SIX, it’s easier than ever to build your activist portfolio and use your
                    shares to make a difference.
                </p>
            </div>
            <a
                className="col-start-2 justify-self-start self-end lg:col-start-3"
                href={`https://www.six-invest.com.au/how-it-works`}
                target="_blank"
                onClick={trackClick}
            >
                <Button
                    variant="outline"
                    className="bg-transparent border-primary text-primary hover:bg-primary hover:text-white"
                >
                    Learn more
                </Button>
            </a>
        </div>
    )
}
