import { Search } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { debounce } from '../utils/utils'
import { InstrumentSearchRecordT } from '../types'
import { searchInstruments } from '@/trading/tradingApi'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'
import { SecurityHead } from '@/securities/securityHead.tsx'
import { Spinner } from '../ui/spinner'
import { Link } from 'react-router-dom'
import { analytics } from '../analytics/analytics'
import { APP_ROUTES } from '@/common/constants.ts'

export default function InstrumentSearch() {
    const [searchTerm, setSearchTerm] = useState('')
    const [searchResult, setSearchResult] = useState<InstrumentSearchRecordT[]>([])
    const [searching, setSearching] = useState<boolean>(false)
    const [showPopover, setShowPopover] = useState<boolean>(false)

    const fetchInstruments = useCallback((query: string) => {
        setSearching(true)
        setShowPopover(true)
        searchInstruments(query)
            .then((res) => {
                setSearchResult(res.matches)
            })
            .finally(() => setSearching(false))
    }, [])

    const clearSearch = () => {
        setSearchTerm('')
        setSearchResult([])
        setShowPopover(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceOnChange = useCallback(debounce(fetchInstruments, 500), [])

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value
        setSearchTerm(query)
        if (query.length === 0) clearSearch()
        if (query.length < 2) return
        debounceOnChange(query)
    }

    const handleClickOnSearchResultItem = () => {
        clearSearch()
        analytics.track('InstrumentSearchResultItem clicked')
    }

    useEffect(() => {
        analytics.track('InstrumentSearch searched', {
            searchedFor: searchTerm,
        })
    }, [searching, searchTerm])

    return (
        <>
            <Popover open={showPopover}>
                <PopoverTrigger asChild>
                    <form
                        className="w-[300px]"
                        onSubmit={(e) => {
                            e.preventDefault()
                        }}
                    >
                        <div className="relative">
                            <Search className="absolute top-3 left-4" />
                            <input
                                type="text"
                                value={searchTerm}
                                name="search-term"
                                onChange={onSearchChange}
                                className="w-full px-12 py-3 border border-gray-400 rounded-full"
                                placeholder="Search Shares and ETFs"
                            />
                        </div>
                    </form>
                </PopoverTrigger>
                <PopoverContent
                    className="w-[300px] p-0 min-h-14 max-h-[60vh] overflow-y-auto flex rounded"
                    align="center"
                    onOpenAutoFocus={(e) => e.preventDefault()}
                    onInteractOutside={() => {
                        setShowPopover(false)
                        clearSearch()
                    }}
                >
                    {searching ? (
                        <Spinner size="medium" className="m-auto" />
                    ) : (
                        <div className="w-full">
                            {searchResult.map((instrument) => (
                                <Link
                                    key={instrument.securityCode}
                                    to={`${APP_ROUTES.SECURITY}/${instrument.securityCode}`}
                                    className="text-inherit"
                                    onClick={handleClickOnSearchResultItem}
                                >
                                    <SecurityHead security={instrument} className="w-full px-2 hover:bg-lime-50" />
                                </Link>
                            ))}
                            {searchResult.length === 0 && (
                                <p className="text-[#52525B] p-2 text-sm">
                                    We were unable to find any matching results for your search
                                </p>
                            )}
                        </div>
                    )}
                </PopoverContent>
            </Popover>
        </>
    )
}
