import { Button, H3 } from '@/common/ui'
import { Dialog, DialogContent } from '@/common/ui/dialog'
import { Check } from 'lucide-react'
import { FC } from 'react'
import useOrderReviewStore from './orderReviewStore'

type Props = {
    open: boolean
    onClose: () => void
}

export const CancelOrderSuccessModal: FC<Props> = ({ open, onClose }) => {
    const { currentOrder } = useOrderReviewStore()
    if (!currentOrder) return
    return (
        <Dialog open={open}>
            <DialogContent
                onClose={onClose}
                className="px-5 py-12 max-h-[90vh] min-h-[40vh] w-[94vw] max-w-[680px] overflow-y-auto xs:px-16 xs:py-16"
            >
                <div className="text-center">
                    <span className="w-14 h-14 bg-secondary rounded-full mx-auto flex justify-center items-center">
                        <Check />
                    </span>
                    <H3 className="text-center py-5">Order Cancellation Accepted</H3>
                    <p>
                        Your request to cancel the order to {currentOrder.orderSide.toLowerCase()}{' '}
                        <span className="font-bold">{currentOrder.units} shares</span> of{' '}
                        <span className="font-bold">{currentOrder.companyName}</span> has been accepted and will
                        processed soon.
                    </p>
                    <div>
                        <Button onClick={onClose} className="mx-auto block mt-8" size="lg" variant="secondary">
                            Back to Portfolio
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
