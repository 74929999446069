import AuthProvider from '@/auth/AuthProvider'
import KycProvider from '@/kyc/KycProvider'
import { TradeModalProvider } from '@/trading/TradeModalProvider'
import SixTermsProvider from '@/user/policies/SixTermsProvider'
import UserProvider from '@/user/UserProvider'
import { AppContainer } from './AppContainer'
import { useFeatureFlags } from '../featureFlags/featureFlags'
import { DowntimeAlert } from './DowntimeAlert'
import { IntercomWidget } from '../intercom/IntercomWidget'
import { useState } from 'react'

export const RootContainer = () => {
    const { showDownTimePage } = useFeatureFlags()
    const [showMessageWidget, setShowMessageWidget] = useState<boolean>(false)

    const handleSendMessage = () => {
        setShowMessageWidget(true)
    }

    const handleHideWidget = () => {
        setShowMessageWidget(false)
    }

    return (
        <>
            {showDownTimePage ? (
                <DowntimeAlert onSendMessage={handleSendMessage} />
            ) : (
                <SixTermsProvider>
                    <AuthProvider>
                        <UserProvider>
                            <KycProvider>
                                <TradeModalProvider>
                                    <AppContainer />
                                </TradeModalProvider>
                            </KycProvider>
                        </UserProvider>
                    </AuthProvider>
                </SixTermsProvider>
            )}
            <IntercomWidget showWidget={showMessageWidget} onHideWidget={handleHideWidget} />
        </>
    )
}
