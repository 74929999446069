import { Button } from '@/common/ui/button'
import { H3 } from '@/common/ui'
import SolidBackgroundModal from '@/common/ui/SolidBackgroundModal'
import { IntercomWidget } from '@/common/intercom/IntercomWidget'

type Props = {
    title: string
    children: React.ReactNode
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
    cta?: string
    isLoading?: boolean
    onBack?: () => void
    showChatWidget?: boolean
}

export function Onboarding({
    title,
    children,
    onSubmit,
    cta = 'Next',
    isLoading,
    onBack,
    showChatWidget = true,
}: Props) {
    return (
        <SolidBackgroundModal>
            <H3 className="text-center">{title}</H3>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    onSubmit(e)
                }}
                className="relative z-0 w-full max-w-xl"
            >
                <div className="py-3 xs:py-6 w-full mb-auto flex flex-col gap-4">{children}</div>
                <div className="pt-4 xs:pt-6 w-full mb-auto flex justify-between">
                    <div>
                        {onBack && (
                            <Button onClick={onBack} variant={'outline'}>
                                Back
                            </Button>
                        )}
                    </div>
                    <Button loading={isLoading} type="submit" variant={'secondary'}>
                        {cta}
                    </Button>
                </div>
            </form>
            {showChatWidget && <IntercomWidget />}
        </SolidBackgroundModal>
    )
}
