import { APP } from '@/common/strings'
import { Button } from '@/common/ui'
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/common/ui/input-otp'
import { Spinner } from '@/common/ui/spinner'
import { Onboarding } from '@/onboarding/OnboardingPageTemplate'
import { autoSignIn, confirmSignUp, resendSignUpCode } from 'aws-amplify/auth'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'
import { analytics } from '@/common/analytics/analytics.ts'

export const ConfirmUser = () => {
    const [code, setCode] = useState('')
    const [verifying, setVerifying] = useState<boolean>(false)
    const [resendingCode, setResendingCode] = useState<boolean>(false)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const userName = searchParams.get('username')
    const verifyUser = async () => {
        if (!userName) {
            toast('Error verifying user, Please try again!')
            navigate('/')
            return
        }
        setVerifying(true)
        try {
            const confirmedSignup = await confirmSignUp({ username: userName, confirmationCode: code })
            if (confirmedSignup.nextStep.signUpStep === 'COMPLETE_AUTO_SIGN_IN') {
                const { nextStep } = await autoSignIn()
                if (nextStep.signInStep === 'DONE') {
                    toast.success('Verification successful!')
                    analytics.track('onboarding_email_verification_succeeded')
                    navigate('/')
                }
            }
            if (confirmedSignup.nextStep.signUpStep === 'DONE') {
                toast.success('Verification successful! Please login again')
                analytics.track('onboarding_email_verification_succeeded')
                navigate('/')
            }
        } catch (error) {
            console.log('error while verifying user', error)
            analytics.track('onboarding_email_verification_failed')
        } finally {
            setVerifying(false)
        }
    }

    const resendVerificationCode = async () => {
        // TODO: fix me, may be use Store, rather than taking username from route search params
        if (!userName) {
            toast('Error verifying user, Please try again!')
            navigate('/')
            return
        }
        setResendingCode(true)
        try {
            const result = await resendSignUpCode({ username: userName })
            analytics.track('onboarding_email_verification_resent')
            console.log('resent code', result)
        } catch (error) {
            console.error('Error while resending verification code', error)
        } finally {
            setResendingCode(false)
        }
    }

    const handleResendCode = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        resendVerificationCode()
    }

    useEffect(() => {
        if (code.length === 6) {
            verifyUser()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code])

    if (!userName) {
        // TODO: or not coming from props
        // TODO: style it
        return <div>Invalid Confirmation link</div>
    }

    return (
        <>
            <Helmet>
                <title>Confirm Account | {APP.title}</title>
            </Helmet>
            <Onboarding
                title="Enter verification code"
                cta="Next"
                isLoading={verifying}
                onSubmit={() => console.log('make it optional')}
                showChatWidget={false}
            >
                <p className="text-center">{userName}</p>
                <div className="grid place-items-center">
                    <InputOTP maxLength={6} onChange={(value) => setCode(value)} value={code}>
                        <InputOTPGroup>
                            <InputOTPSlot index={0} />
                            <InputOTPSlot index={1} />
                            <InputOTPSlot index={2} />
                            <InputOTPSlot index={3} />
                            <InputOTPSlot index={4} />
                            <InputOTPSlot index={5} />
                        </InputOTPGroup>
                    </InputOTP>
                </div>
                <div className="text-center">
                    <span>Didn't receive the code? </span>
                    <Button
                        variant="link"
                        className="p-1 h-8 text-primary font-bold text-lg"
                        onClick={handleResendCode}
                        disabled={resendingCode}
                    >
                        Resend It {resendingCode && <Spinner size="default" />}
                    </Button>
                </div>
            </Onboarding>
        </>
    )
}
