import Money from '@/common/models/money'
import { TRADING } from '@/common/strings'
import { InfoIconTooltip } from '@/common/ui/tooltip'
import { FC } from 'react'

type Props = {
    fee: Money
    amount: Money
    availableFunds: Money
    isSellOrder: boolean
    displayWithPrecision?: number
}

const Item = ({ label, value, tooltip }: { label: string; value: string; tooltip?: string }) => (
    <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
            <span className="text-black text-sm font-normal font-['Inter'] leading-tight">{label}:</span>
            {tooltip && <InfoIconTooltip>{tooltip}</InfoIconTooltip>}
        </div>
        <strong className="text-right text-black text-base font-bold font-['Inter'] leading-snug">{value}</strong>
    </div>
)

export const TradeMoneySummary: FC<Props> = ({
    fee,
    amount,
    availableFunds,
    isSellOrder,
    displayWithPrecision = 2,
}) => {
    const amountInclFee = isSellOrder ? amount.subtract(fee) : amount.add(fee)

    return (
        <>
            <div className="text-black text-xl font-semibold font-['Inter'] leading-7">Total</div>
            <div className="space-y-2 mt-2 text-xs xs:text-base">
                <Item
                    label="Estimated amount"
                    value={`${amount.toString(displayWithPrecision)}`}
                    tooltip={isSellOrder ? undefined : 'Estimated amount includes a 2% buffer for Market Orders'}
                />
                <Item
                    label="Estimated brokerage"
                    value={`${fee.toString(displayWithPrecision)}`}
                    tooltip={TRADING.brokerageNote}
                />
                <Item label="Estimate total" value={`${amountInclFee.toString(displayWithPrecision)}`} />
                {!isSellOrder && availableFunds.lessThan(amountInclFee) ? (
                    <p className="text-red-700 text-right text-sm">Not enough available funds</p>
                ) : null}
            </div>
        </>
    )
}
