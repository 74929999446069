import { cn } from '@/common/utils/utils'
import { useFetchHoldingsHistory, useListHoldings, useListUserPledges } from '@/trading/tradingQueries'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { PortfolioValueGraph } from '@/common/ui/PortfolioValueGraph.tsx'
import { analytics } from '@/common/analytics/analytics.ts'

function GraphLabels({
    labels,
    onSelect,
    selectedLabel,
}: {
    labels: string[]
    onSelect: (label: string) => void
    selectedLabel: string
}) {
    return (
        <div className="flex-col justify-start items-end gap-2.5 inline-flex">
            <div className="justify-start items-start gap-2.5 inline-flex">
                {labels.map((label) => (
                    <div
                        className={cn('px-3 py-1.5 rounded-full justify-start items-center gap-6 flex', {
                            'bg-[#e3fbc5]': label === selectedLabel,
                        })}
                    >
                        <button onClick={() => onSelect(label)} className="text-center text-[#223a32] text-sm">
                            {label}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}

interface PortfolioValueProps {
    hidePledged?: boolean
}

export function PortfolioValue({ hidePledged }: PortfolioValueProps) {
    const [selectedRange, setSelectedRange] = useState<string>('1W')
    const { data: holdingsHistory, isLoading } = useFetchHoldingsHistory(selectedRange)
    const { data: holdingsData } = useListHoldings()
    const { data: pledges } = useListUserPledges()
    const [graphValues, setGraphValues] = useState<{ x: string; y: number }[]>([])

    const rangePresets: string[] = ['1W', '1M', '6M', '1Y']

    useEffect(() => {
        if (isLoading) return

        const pledgedSecurities = hidePledged ? pledges?.map((pledge) => pledge.securityCode) || [] : []
        const filteredHistory = holdingsHistory?.filter(
            (entry: { securityCode: string }) => !pledgedSecurities.includes(entry.securityCode),
        )
        const filteredHoldings =
            holdingsData?.holdings.filter((entry) => !pledgedSecurities.includes(entry.securityCode)) ?? []

        const filledData = filteredHistory || []
        const firstDate = moment(holdingsHistory[0]?.endOfTradingDate)
        const currentDate = moment().subtract(
            {
                '1W': 7,
                '1M': 1,
                '6M': 6,
                '1Y': 12,
            }[selectedRange],
            selectedRange === '1W' ? 'days' : 'months',
        )
        const diff = Math.abs(currentDate.diff(firstDate, 'days'))
        if (diff > filledData.length - 1) {
            for (let i = 0; i < diff - filledData.length; i++) {
                filledData.unshift({
                    endOfTradingDate: firstDate.clone().subtract(i, 'days').format('YYYY-MM-DD'),
                    totalValue: 0,
                })
            }
        }
        const lastDate = moment(filledData[filledData.length - 1]?.endOfTradingDate)
        const today = moment()
        while (lastDate.isBefore(today, 'day')) {
            lastDate.add(1, 'day')
            filledData.push({
                endOfTradingDate: lastDate.clone().format('YYYY-MM-DD'),
                totalValue: filledData[filledData.length - 1]?.totalValue || 0,
            })
        }
        if (filteredHoldings) {
            filledData[filledData.length - 1].totalValue =
                filteredHoldings?.reduce((acc, cur) => {
                    return acc + cur.currentValue
                }, 0) ?? 0
        }
        setGraphValues(
            filledData?.map((d: { endOfTradingDate: any; totalValue: any }) => ({
                x: d.endOfTradingDate,
                y: d.totalValue,
            })),
        )
    }, [hidePledged, holdingsData, holdingsHistory, isLoading, pledges, selectedRange])

    return (
        <div className="p-6 h-[400px] w-full bg-white rounded-[10px] shadow-lg border border-[#d5d8d3] flex-col justify-between items-start inline-flex">
            <div className="mb-3 self-stretch justify-between items-center inline-flex">
                <div className="w-28 text-black text-base font-semibold font-['Inter'] leading-snug">
                    Portfolio value
                </div>
                <GraphLabels
                    labels={rangePresets}
                    onSelect={(label) => {
                        analytics.track('PortfolioGraph PresetRangeClicked', {
                            range: label,
                        })
                        setSelectedRange(label)
                    }}
                    selectedLabel={selectedRange}
                />
            </div>
            {isLoading ? (
                <div className="w-full animate-pulse flex space-x-4">
                    <div className="rounded-full bg-stone-100 h-10 w-10"></div>
                    <div className="flex-1 space-y-6 py-1">
                        <div className="h-2 bg-stone-100 rounded"></div>
                        <div className="space-y-3">
                            <div className="grid grid-cols-3 gap-4">
                                <div className="h-2 bg-stone-100 rounded col-span-2"></div>
                                <div className="h-2 bg-stone-100 rounded col-span-1"></div>
                            </div>
                            <div className="h-2 bg-stone-100 rounded"></div>
                        </div>
                    </div>
                </div>
            ) : (
                <PortfolioValueGraph data={graphValues} />
            )}
        </div>
    )
}
