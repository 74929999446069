import { cn } from '@/common/utils/utils'

type Props = {
    children: React.ReactNode
    className?: string
}

export function UnorderedList({ children, className }: Props) {
    return <ul className={cn('list-disc mb-6 pl-6', className)}>{children}</ul>
}
