import { useParams } from 'react-router-dom'
import { useListHoldings } from '@/trading/tradingQueries'
import { Button } from '@/common/ui/button'
import { H1 } from '@/common/ui'
import useTradeModalStore from '@/trading/tradeModalStore'
import { SecurityAvatar } from './securityAvatar.tsx'

export const SecurityDetailsPageHeader = ({ securityName }: { securityName: string }) => {
    const { securityCode } = useParams()
    const { data: holdings } = useListHoldings()
    const heldPositions = holdings?.holdings.find((h) => h.securityCode === securityCode)

    const { setSecurityCodes, setOrderAction } = useTradeModalStore()

    const handleBuy = () => {
        if (!securityCode) return
        setSecurityCodes([securityCode])
        setOrderAction('BUY')
    }

    const handleSell = () => {
        if (!securityCode) return
        setSecurityCodes([securityCode])
        setOrderAction('SELL')
    }

    if (!securityCode) return null

    return (
        <header className="flex flex-col sm:flex-row justify-between gap-4 sm:items-center mb-8">
            <div className="flex">
                <SecurityAvatar securityCode={securityCode} height={16} width={16} />
                <div className="flex flex-col px-4">
                    <H1 className="mb-0">{securityCode}</H1>
                    <p className="text-sm text-accent-foreground">{securityName}</p>
                </div>
            </div>
            <div className="flex gap-2">
                <Button className="bg-secondary text-black" onClick={handleBuy}>
                    Buy
                </Button>
                <Button className="bg-destructive" onClick={handleSell} disabled={!heldPositions}>
                    Sell
                </Button>
            </div>
        </header>
    )
}
