import { getPledgeBySecurityCode } from '@/campaigns/campaignsApi'
import { useFetchCampaignsList } from '@/campaigns/campaignsQueries'
import { CampaignT } from '@/common/types'
import { useListHoldings } from '@/trading/tradingQueries'
import { useEffect, useState } from 'react'

type Pledges = {
    [key: string]: number
}

export type CampaignsWithHoldings = CampaignT & {
    pledges: Pledges
    pledgedCompanies: CampaignT['targetCompaniesMetadata']
}

export const useCampaignsWithHoldings = () => {
    const { data: holdingsData, isLoading: isLoadingHoldings } = useListHoldings()
    const { data: campaignsList, isLoading: isLoadingCampaigns } = useFetchCampaignsList()
    const [campaignsWithHoldings, setCampaignsWithHoldings] = useState<CampaignsWithHoldings[] | []>([])
    const [isLoadingCampaignsWithHoldings, setIsLoadingCampaignsWithHoldings] = useState(false)

    useEffect(() => {
        setIsLoadingCampaignsWithHoldings(true)

        const fetchCampaignsWithHoldings = async () => {
            const results = await Promise.all(
                campaignsList?.campaigns?.map(async (campaign) => {
                    const holdings = holdingsData?.holdings.filter((holding) => {
                        return campaign.targetCompaniesAsxCodes.includes(holding.securityCode)
                    })

                    if (!holdings || holdings.length === 0) {
                        return null
                    }

                    const pledges: Pledges = {}
                    await Promise.all(
                        campaign.targetCompaniesAsxCodes.map(async (code) => {
                            try {
                                pledges[`${code}`] = await getPledgeBySecurityCode(campaign.campaignId, code)
                            } catch (e) {
                                pledges[`${code}`] = 0
                            }
                        }),
                    )

                    const flattenedHoldings = holdings.map((holding) => holding.securityCode)

                    const pledgedCompanies = campaign.targetCompaniesMetadata?.filter((company) =>
                        flattenedHoldings.includes(company.code),
                    )

                    return { ...campaign, pledges, pledgedCompanies }
                }) ?? [],
            ).then((results) => results.filter((result) => result !== null))

            // @ts-ignore
            setCampaignsWithHoldings(results)
            setIsLoadingCampaignsWithHoldings(false)
        }

        !isLoadingHoldings && !isLoadingCampaigns && fetchCampaignsWithHoldings()
    }, [campaignsList, holdingsData, isLoadingHoldings, isLoadingCampaigns])

    return { campaignsWithHoldings, isLoading: isLoadingCampaignsWithHoldings }
}
