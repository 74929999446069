import { API_ROUTES } from '@/common/constants'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
    campaignPledge,
    campaignSubscribe,
    campaignUnsubscribe,
    getAgencyAgreement,
    getCampaignDetails,
    getCampaignSubscriptions,
    getCampaignsList,
    submitAgencyAgreement,
} from './campaignsApi'
import { Maybe } from '@/common/types'
import { AgencyAgreementT } from './types'

export const useFetchCampaignsList = () => {
    return useQuery({
        queryKey: [API_ROUTES.campaignsList],
        queryFn: getCampaignsList,
    })
}

export const useFetchCampaignDetails = (webflowItemId: Maybe<string>) => {
    return useQuery({
        queryKey: [API_ROUTES.campaignDetails, webflowItemId],
        queryFn: () => getCampaignDetails(webflowItemId ?? ''),
        enabled: !!webflowItemId,
    })
}

export const useFetchSubscriptions = () => {
    return useQuery({
        queryKey: [API_ROUTES.campaignSubscriptions],
        queryFn: getCampaignSubscriptions,
    })
}

export const useGetAgencyAgreement = () => {
    return useQuery({
        queryKey: [API_ROUTES.agencyAgreement],
        queryFn: getAgencyAgreement,
    })
}

export const useSubmitAgencyAgreement = () => {
    return useMutation({
        mutationFn: (agencyAgreement: AgencyAgreementT) => submitAgencyAgreement(agencyAgreement),
    })
}

export const useSubscribeCampaign = () => {
    return useMutation({
        mutationFn: (campaignId: string) => campaignSubscribe(campaignId),
    })
}

export const useUnsubscribeCampaign = () => {
    return useMutation({
        mutationFn: (campaignId: string) => campaignUnsubscribe(campaignId),
    })
}

type PledgeRequest = {
    campaignId: string
    securityCode: string
}

export const usePledgeForCampaign = () => {
    return useMutation({
        mutationFn: (pledge: PledgeRequest) => campaignPledge(pledge.campaignId, pledge.securityCode),
    })
}
