import { app } from '@/common/api/apiClient'
import { API_ROUTES } from '@/common/constants'
import { insertStringVariables } from '@/common/utils/utils'
import { useQuery } from '@tanstack/react-query'

type EsgProfileResponse = {
    company: {
        asxCode: string
        companyName: string
        securityNameOfficial: string
        logo: {
            url: string
        }
    }
    sixthSense: {
        json: any
    }
    tagsCollection: {
        items: {
            tag: string
            isTagPositive: boolean
        }[]
    }
    content: any
}

export const useEsgProfile = (securityCode?: string) => {
    return useQuery({
        queryKey: [API_ROUTES.esgProfile, securityCode],
        queryFn: async () =>
            await app.get<EsgProfileResponse>(`${insertStringVariables(API_ROUTES.esgProfile, securityCode || '')}`),
        enabled: !!securityCode,
    })
}
