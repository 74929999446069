import { NavLink } from 'react-router-dom'
import { Logo } from '../assets/svg'
import { H1 } from './h1'

type Props = {
    onSendMessage: () => void
}

export const DowntimeAlert = ({ onSendMessage }: Props) => {
    return (
        <div className="w-1/3 m-auto py-10">
            <div className="block pt-6 pb-6">
                <NavLink to="/">
                    <Logo width={100} height={30} />
                </NavLink>
            </div>
            <H1 className="text-[#223A32] font-normal font-display">Greener Pastures Ahead: We're Updating!</H1>
            <p className="text-base font-normal font-display text-lg">
                We're in the process of making our site more sustainable. Please{' '}
                <span className="line-through">six</span> stick with us as we work on these exciting changes! Follow us
                on{' '}
                <a
                    className="relative underline bg-gradient-to-tr from-[#FFC74B_5.66%] via-[#FF5028_11%] via-[#B92DBF_15%] to-[#0341E1_100%] text-transparent bg-clip-text"
                    href="https://www.instagram.com/six.invest.au/"
                    target="_blank"
                >
                    Instagram,
                    <span className="absolute left-0 bottom-0 w-full h-[2px] bg-gradient-to-tr from-[#FFC74B_5.66%] via-[#FF5028_11%] via-[#B92DBF_15%] to-[#0341E1_100%] rounded-full"></span>
                </a>{' '}
                <a className="underline text-[#4267B2]" href="https://www.facebook.com/six.invest.au" target="_blank">
                    Facebook,
                </a>{' '}
                or{' '}
                <a
                    className="underline text-[#0077B5]"
                    href="https://www.linkedin.com/company/sustainable-investment-exchange/"
                    target="_blank"
                >
                    LinkedIn
                </a>{' '}
                for updates.
            </p>
            <p className="text-base font-normal font-display py-4 text-lg">
                If you'd like to get in touch with us, please{' '}
                <a role="button" onClick={() => onSendMessage()} className="underline text-[#137157]">
                    send us a message
                </a>{' '}
                or email us at info@six-invest.com.au.
            </p>
        </div>
    )
}
