import { Button } from '@/common/ui'
import { useSubscribeCampaign, useUnsubscribeCampaign } from '@/campaigns/campaignsQueries'
import { useEffect, useState } from 'react'
import { CampaignT } from '@/common/types'
import { CheckCircle2, Heart } from 'lucide-react'
import clsx from 'clsx'
import { analytics } from '@/common/analytics/analytics'
import { CampaignStatusPill } from '../campaigns/CampaignStatusPill'
import { CountdownTimer } from '../campaigns/CountdownTimer'
import { SecurityAvatar } from '@/securities/securityAvatar.tsx'
import { getPledgeBySecurityCode } from '../campaigns/campaignsApi'
import { useFetchCompanyBySecurityCode } from '@/trading/tradingQueries.ts'
import { getCompanyBySecurityCode } from '@/trading/tradingApi.ts'
import { APP_ROUTES } from '@/common/constants.ts'
import { Link } from 'react-router-dom'
import { Deadline } from '@/common/ui/Deadline.tsx'
import moment from 'moment/moment'
import { DotFilledIcon } from '@radix-ui/react-icons'

export type CampaignWithSubscription = CampaignT & { isSubscribed?: boolean; pledged?: boolean }

type Props = {
    campaign: CampaignWithSubscription
    securityCode: string
    isPledged: boolean
    otherPledgedSecurityCodes: string[]
}

export const ProfileActiveCampaign = ({ campaign, securityCode, isPledged, otherPledgedSecurityCodes }: Props) => {
    const subscribeCampaign = useSubscribeCampaign()
    const unsubscribeCampaign = useUnsubscribeCampaign()
    const { data: targetCompanyProfile } = useFetchCompanyBySecurityCode(securityCode)

    const [subscribed, setSubscribed] = useState<boolean>(campaign.isSubscribed ?? false)
    const [followers, setFollowers] = useState<number>(campaign.followers)
    const [deadline, setDeadline] = useState<moment.Moment>()
    const [pledges, setPledges] = useState<{ [key: string]: number }>({})
    const [companyProfiles, setCompanyProfiles] = useState<{ [key: string]: any }>({})
    const [otherCompanySecurityCodes, setOtherCompanySecurityCodes] = useState<string[]>([])

    const handleFollow = () => {
        setSubscribed(true)
        setFollowers(followers + 1)
        subscribeCampaign.mutate(campaign.campaignId)
        analytics.track('Campaign Followed', {
            campaign_slug: campaign.campaignId,
            campaign_name: campaign.title,
            source_page: 'security',
        })
    }

    const handleUnfollow = () => {
        setSubscribed(false)
        setFollowers(followers - 1)
        unsubscribeCampaign.mutate(campaign.campaignId)
        analytics.track('Campaign Unfollowed', {
            campaign_slug: campaign.campaignId,
            campaign_name: campaign.title,
            source_page: 'security',
        })
    }

    const toggleFollow = () => {
        if (subscribed) {
            handleUnfollow()
        } else {
            handleFollow()
        }
    }

    useEffect(() => {
        setSubscribed(campaign.isSubscribed ?? false)
    }, [campaign.isSubscribed])

    useEffect(() => {
        setOtherCompanySecurityCodes(campaign.targetCompaniesAsxCodes.filter((other) => other !== securityCode))
    }, [campaign.targetCompaniesAsxCodes, securityCode])

    useEffect(() => {
        const updateProfilesAndPledges = async () => {
            try {
                const allCodes = campaign.targetCompaniesAsxCodes.filter((code) => code !== securityCode)
                setOtherCompanySecurityCodes(allCodes)

                const [profiles, pledgesMap] = await Promise.all([
                    Promise.all(allCodes.map((code) => getCompanyBySecurityCode(code))),
                    Promise.all(
                        campaign.targetCompaniesAsxCodes.map(async (code) => ({
                            [code]: await getPledgeBySecurityCode(campaign.campaignId, code).catch(() => 0),
                        })),
                    ),
                ])

                setCompanyProfiles(
                    profiles.reduce((acc, curr) => {
                        // @ts-ignore
                        acc[curr.securityCode] = curr
                        return acc
                    }, {}),
                )

                setPledges(pledgesMap.reduce((acc, curr) => ({ ...acc, ...curr }), {}))
            } catch (error) {
                console.error('Error updating profiles and pledges:', error)
            }
        }

        updateProfilesAndPledges()
    }, [campaign.targetCompaniesAsxCodes, campaign.campaignId, securityCode])

    useEffect(() => {
        const deadline = campaign.targetCompaniesMetadata?.find(
            (company) => company.code === securityCode,
        )?.campaignDeadline

        if (!deadline) return
        const deadlineMoment = moment(deadline)
        if (deadlineMoment.isBefore(moment())) return
        setDeadline(deadlineMoment)
    }, [campaign, securityCode])

    const Progress = ({ securityCode, profile }: { securityCode: string; profile?: any }) => {
        return (
            <>
                <div className="flex flex-col gap-1 mb-4 border-y-1 py-6">
                    {Object.keys(pledges)
                        .filter((code) => securityCode === code)
                        .map((code) => {
                            return (
                                <div className="flex items-start mb-1">
                                    <div className="flex items-center mr-2">
                                        <SecurityAvatar className="mr-2" securityCode={code} height={8} width={8} />
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex mb-1 items-center">
                                            <span className="font-bold whitespace-nowrap overflow-hidden">
                                                {profile?.securityName}
                                            </span>
                                            <span className="font-medium pl-3 flex-shrink-0">
                                                {profile?.securityCode}
                                            </span>
                                            {isPledged && (
                                                <span className="flex items-center ml-auto flex-shrink-0">
                                                    <CheckCircle2
                                                        className="ml-2 mr-1"
                                                        style={{ width: '16px', height: '16px', strokeWidth: 2 }}
                                                    />
                                                    <span className="flex-1 hidden sm:inline-block font-bold">
                                                        Pledged
                                                    </span>
                                                </span>
                                            )}
                                        </div>
                                        <div className="relative w-full h-[8px] rounded-full overflow-hidden">
                                            <style>{`.${code} { --bar-width: ${Math.min(pledges[code], 100)}%; }`}</style>
                                            <div className={`absolute bg-[#F0F0F0] w-full h-full ${code}`}>
                                                <div className={`bg-electric-lime w-[--bar-width] h-full`}></div>
                                            </div>
                                        </div>
                                        <span className="flex items-center justify-left text-[13px]">
                                            {deadline && (
                                                <>
                                                    <Deadline iconSize={16} deadline={deadline.toISOString()} />
                                                    <DotFilledIcon className="mx-1" />
                                                </>
                                            )}
                                            <span className="">
                                                <span className="font-bold"> {pledges[code]}</span>
                                                <span>/100 shareholders</span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </>
        )
    }

    const OtherCompanyProgress = ({ securityCode, profile }: { securityCode: string; profile?: any }) => {
        return (
            <>
                <div className="flex flex-col gap-1 mb-4">
                    {Object.keys(pledges)
                        .filter((code) => securityCode === code)
                        .map((code) => {
                            return (
                                <Link
                                    className="no-underline text-inherit"
                                    to={`${APP_ROUTES.SECURITY}/${securityCode}`}
                                >
                                    <div className="flex items-start mb-1">
                                        <div className="flex items-center mr-2">
                                            <SecurityAvatar className="mr-2" securityCode={code} height={8} width={8} />
                                        </div>
                                        <div className="flex-1">
                                            <div className="flex mb-1 items-center">
                                                <span className="font-bold">{profile?.securityName}</span>
                                                <span className="font-medium pl-3">{profile?.securityCode}</span>
                                            </div>
                                            <div className="relative w-full h-[8px] rounded-full overflow-hidden">
                                                <style>{`.${code} { --bar-width: ${Math.min(pledges[code], 100)}%; }`}</style>
                                                <div className={`absolute bg-[#F0F0F0] w-full h-full ${code}`}>
                                                    <div className={`bg-electric-lime w-[--bar-width] h-full`}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}
                </div>
            </>
        )
    }

    function otherCompanyProfiles() {
        return (
            <>
                {otherCompanySecurityCodes.length > 0 && (
                    <div className="grid gap-2 grid-cols-1">
                        {otherCompanySecurityCodes.map((otherSecurityCode) => (
                            <div
                                key={otherSecurityCode}
                                className={`h-[72px] p-4 rounded-lg border duration-300 gap-2 hover:bg-[#ECEFE9] bg-[#F9F9F9]`}
                                onClick={() => {
                                    analytics.track('ethical_profile_active_campaign_card_other_company_clicked', {
                                        companyCode: securityCode,
                                        campaignId: campaign.campaignId,
                                        campaignTitle: campaign.title,
                                        shares_pledged: otherPledgedSecurityCodes.includes(otherSecurityCode),
                                        shareholders_pledged: pledges[`${otherSecurityCode}`],
                                    })
                                }}
                            >
                                <OtherCompanyProgress
                                    profile={companyProfiles[otherSecurityCode]}
                                    securityCode={otherSecurityCode}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </>
        )
    }

    function trackCurrentCompanyCampaignCardClicked() {
        analytics.track('ethical_profile_active_campaign_card_clicked', {
            companyCode: securityCode,
            campaignId: campaign.campaignId,
            campaignTitle: campaign.title,
            shares_pledged: otherPledgedSecurityCodes.includes(securityCode),
            shareholders_pledged: pledges[`${securityCode}`],
            time_remaining_days: deadline?.diff(moment(), 'days'),
            number_of_companies: campaign.targetCompaniesAsxCodes.length,
            followers: followers,
        })
    }

    return (
        <div className="border rounded-xl border-slate-200 mb-4 hover:shadow-bottom-right transition-shadow duration-300">
            <div key={campaign.title} className="flex items-stretch">
                {campaign.heroImage && (
                    <Link
                        className="flex-shrink-0 w-1/3 mr-4 overflow-hidden"
                        to={`/campaigns/${campaign.campaignId}`}
                        aria-label={`View ${campaign.title} campaign`}
                        onClick={trackCurrentCompanyCampaignCardClicked}
                    >
                        <img
                            className="w-full h-full object-cover rounded-tl-lg rounded-bl-lg"
                            src={campaign.heroImage.url}
                            alt={campaign.heroImage.alt ?? campaign.title}
                        />
                    </Link>
                )}
                <div className="flex-1 text-black text-sm font-normal font-['Inter'] flex flex-col justify-between pl-2 pr-4 py-6">
                    {campaign.status && (
                        <CampaignStatusPill status={campaign.status} campaignId={campaign.campaignId} />
                    )}

                    <Link
                        to={`/campaigns/${campaign.campaignId}`}
                        aria-label={`View ${campaign.title} campaign`}
                        onClick={trackCurrentCompanyCampaignCardClicked}
                    >
                        <p className="text-black text-xl font-semibold font-['Inter'] leading-7">{campaign.title}</p>
                    </Link>

                    <div className="flex justify-between py-1 mb-2 mt-2 flex-col gap-2 xs:flex-row">
                        <Button variant="ghost" className="hover:bg-transparent px-2 md:px-0" onClick={toggleFollow}>
                            <Heart className={clsx({ 'text-destructive fill-destructive': subscribed })} />
                            <span>{followers} followers</span>
                        </Button>
                    </div>

                    <p className="pb-8">{campaign.description}</p>
                    <Progress profile={targetCompanyProfile} securityCode={securityCode} />
                    {campaign.countdownDate && (
                        <CountdownTimer countdownDate={campaign.countdownDate} className="px-6 pb-6" />
                    )}

                    {otherCompanyProfiles()}
                </div>
            </div>
        </div>
    )
}
