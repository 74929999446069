import { ORDER_TYPE } from '@/common/constants'
import { OrderType } from '@/common/types'
import { Select, SelectTrigger, SelectValue, SelectContent, SelectGroup, SelectItem } from '@/common/ui/select'
import { FC } from 'react'
import { OrderTypeLabel } from './OrderTypeLabel'

type Props = {
    orderType: OrderType
    setOrderType: (orderType: OrderType) => void
    isMarketClosed: boolean
    defaultOrderType: OrderType
}

export const OrderTypeSelector: FC<Props> = ({ orderType, setOrderType, isMarketClosed, defaultOrderType }) => {
    return (
        <div className="flex flex-row justify-end items-center">
            <div className="w-1/2">
                <OrderTypeLabel />
            </div>
            <div className="w-1/2">
                <Select value={orderType} onValueChange={(value: OrderType) => setOrderType(value || defaultOrderType)}>
                    <SelectTrigger
                        id="order-type"
                        className="w-full rounded-full gap-2 border border-gray-300 py-2 px-4 !mt-0 focus:ring-transparent justify-end"
                    >
                        <SelectValue placeholder="Select order type" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectItem value="LIMIT">{ORDER_TYPE.LIMIT}</SelectItem>
                            <SelectItem value="MARKET_TO_LIMIT" disabled={isMarketClosed}>
                                {ORDER_TYPE.MARKET_TO_LIMIT}
                                {isMarketClosed && ' (Only available when market is open)'}
                            </SelectItem>
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
        </div>
    )
}
