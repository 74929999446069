import { analytics } from '@/common/analytics/analytics'
import { Link } from 'react-router-dom'

export function GettingStartedPreview() {
    return (
        <>
            <div className="h-[22px] justify-start mb-3 mt-4 items-center inline-flex">
                <div className="text-center text-black text-xl font-normal">Get started investing ethically</div>
            </div>
            <div className="p-6 bg-white shadow-lg rounded-[10px] shadow border border-[#d5d8d3] justify-start items-center gap-6 flex-wrap inline-flex">
                <img width={570} height={446} className="bg-cover rounded" src="/adam-stage.jpg" />
                <div className="h-[150px] w-full xl:w-[460px] flex-col justify-start items-start gap-6 inline-flex">
                    <div className="text-left text-black text-base font-medium font-['Inter'] leading-snug">
                        What shares should I buy first?
                    </div>
                    <div className="self-stretch text-black text-sm font-normal font-['Inter'] leading-tight">
                        Founder of SIX Adam Verwey shares his tips on how you can start investing ethically with any
                        amount.{' '}
                    </div>
                    <Link
                        to="/getting-started"
                        className="h-10 px-6 py-2 bg-[#ecefe9] rounded-[100px] justify-center items-center gap-2.5 inline-flex"
                        onClick={() => analytics.track('GettingStartedPage Viewed')}
                    >
                        <div className="text-black text-sm font-medium font-['Inter'] leading-tight">Read more</div>
                    </Link>
                </div>
            </div>
        </>
    )
}
