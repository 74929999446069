import useTradeStore from '@/trading/tradeStore.ts'

export const SupportedCampaigns = () => {
    const isPledgedAndCampaignsForSecurity = useTradeStore((state) => state.isPledgedAndCampaignsForSecurity)
    const campaignsForSecurity = useTradeStore((state) => state.campaignsForSecurity)
    const action = useTradeStore((state) => state.orderAction)

    if (!isPledgedAndCampaignsForSecurity || action !== 'BUY') {
        return <></>
    }
    return (
        <>
            <div className="text-black text-sm font-normal font-['Inter'] mt-6 mb-4">Campaign(s) you’ll support:</div>
            {campaignsForSecurity?.map((campaign) => {
                return (
                    <div key={campaign.title} className="flex items-start mb-4">
                        {campaign.heroImage && (
                            <img
                                className="w-1/2 rounded-md mr-4"
                                src={campaign.heroImage.url}
                                alt={campaign.heroImage.alt ?? campaign.title}
                                style={{ maxWidth: '30%' }}
                            />
                        )}
                        <div className="flex-1 text-black text-sm font-normal font-['Inter']">
                            <p className="font-bold">{campaign.title}</p>
                            <p className="mt-4"> {campaign.description}</p>
                        </div>
                    </div>
                )
            })}
        </>
    )
}
