import { useNavigate } from 'react-router'
import { Helmet } from 'react-helmet'
import { useCallback, useEffect } from 'react'

import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { Button } from '@/common/ui/button'
import { APP_ROUTES } from '@/common/constants'
import { APP } from '@/common/strings'
import SolidBackgroundModal from '@/common/ui/SolidBackgroundModal'
import { H3 } from '@/common/ui'
import { CircleCheckBig, Timer } from 'lucide-react'
import useUserStore from '@/user/userStore'
import { analytics } from '@/common/analytics/analytics'
import { useFetchKYC } from './kycQueries'
import { useFeatureFlags } from '@/common/featureFlags/featureFlags'

export function KycInit() {
    const { showOldKycCompleteModal } = useFeatureFlags()
    const { user } = useUserStore()
    const { data: kyc } = useFetchKYC({ enabled: true })
    const navigate = useNavigate()

    const goToKYC = () => {
        analytics.track('KYC Initiated')
        navigate(APP_ROUTES.KYC)
    }

    const skipKyc = useCallback(() => {
        analytics.track('KYC Skipped')
        navigate('/')
    }, [navigate])

    useEffect(() => {
        if (!kyc) return
        if (!kyc.canInitiateKyc) {
            skipKyc()
        }
    }, [kyc, skipKyc])

    if (!user || !kyc) {
        return <FullScreenLoader dark />
    }

    return (
        <>
            <Helmet>
                <title>ID Verification | {APP.title}</title>
            </Helmet>
            {showOldKycCompleteModal ? (
                <SolidBackgroundModal>
                    <div className="flex flex-col items-center">
                        <CircleCheckBig className="text-primary-light size-12" />
                        <H3 className="text-center py-4">Thanks for joining us!</H3>
                        <p className="text-center text-balance">
                            You'll be able to access your account now, but your trading account has not yet been opened.
                            In order to trade on the SIX platform, you must fill out a verification form for proof of
                            identity. Do you wish to&nbsp;continue?
                        </p>
                        <div className="flex flex-col w-full xs:flex-row justify-between">
                            <Button variant="secondary" onClick={goToKYC}>
                                Yes, complete form now
                            </Button>
                            <Button variant="ghost" onClick={skipKyc}>
                                I'll do it later
                            </Button>
                        </div>
                    </div>
                </SolidBackgroundModal>
            ) : (
                <SolidBackgroundModal>
                    <div className="flex flex-col items-center">
                        <CircleCheckBig className="text-primary-light size-12" />
                        <H3 className="text-center py-4">Thanks for creating an account</H3>
                        <p className="text-center text-balance">
                            In order to trade on the SIX platform, you must fill out a verification form for proof of
                            identity. Do you wish to continue?
                        </p>
                        <div className="flex border gap-4 items-center mt-6 mb-12 px-4 py-4">
                            <div className="pb-6">
                                <Timer />
                            </div>
                            <p>Over 80% of SIXers finish filling the form in less than 3 minutes.</p>
                        </div>
                        <div className="flex flex-col w-full xs:flex-row justify-between">
                            <Button variant="ghost" onClick={skipKyc}>
                                I'll do it later
                            </Button>
                            <Button variant="secondary" onClick={goToKYC}>
                                Yes, complete form now
                            </Button>
                        </div>
                    </div>
                </SolidBackgroundModal>
            )}
        </>
    )
}
