import { H2 } from '@/common/ui'
import { cn } from '@/common/utils/utils'
import { Clock } from 'lucide-react'
import { useEffect, useState } from 'react'

export function CountdownTimer({ countdownDate, className }: { countdownDate: string; className?: string }) {
    const [daysLeft, setDaysLeft] = useState<number>()
    const [hoursLeft, setHoursLeft] = useState<number>()
    const [minutesLeft, setMinutesLeft] = useState<number>()
    const [secondsLeft, setSecondsLeft] = useState<number>()
    const [show, setShow] = useState<boolean>(false)

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date().getTime()
            const distance = new Date(countdownDate).getTime() - now

            if (distance < 0) {
                clearInterval(intervalId)
                setShow(false)
                return
            }

            const days = Math.floor(distance / (1000 * 60 * 60 * 24))
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            const seconds = Math.floor((distance % (1000 * 60)) / 1000)

            setDaysLeft(days)
            setHoursLeft(hours)
            setMinutesLeft(minutes)
            setSecondsLeft(seconds)
            setShow(true)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [countdownDate])

    const TimeCol = ({ time, label }: { time: number; label: string }) => (
        <div className="flex flex-col items-center">
            <H2 className="text-2xl m-0 p-0">{time}</H2>
            <span className="text-[10px] sm:text-xs">{label}</span>
        </div>
    )

    return (
        <div className={cn('w-full text-white', className)}>
            {show && (
                <div className="bg-[#223A32DD] h-full rounded-md p-4">
                    <div className="flex gap-1 items-center mb-3">
                        <Clock size={12} />
                        <span className="text-xs">Ends in</span>
                    </div>
                    <div className="grid grid-cols-4">
                        <TimeCol time={daysLeft ?? 0} label="Days" />
                        <TimeCol time={hoursLeft ?? 0} label="Hours" />
                        <TimeCol time={minutesLeft ?? 0} label="Minutes" />
                        <TimeCol time={secondsLeft ?? 0} label="Seconds" />
                    </div>
                </div>
            )}
        </div>
    )
}
