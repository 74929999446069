import { FC } from 'react'
import { Switch } from '@/common/ui/switch'

interface HidePledgedToggleProps {
    isPledged: boolean
    setIsPledged: (isChecked: boolean) => void
}

export const HidePledgedToggle: FC<HidePledgedToggleProps> = ({ isPledged, setIsPledged }) => {
    const handleToggle = (isChecked: boolean) => {
        localStorage.setItem('hidePledged', JSON.stringify(isChecked))
        setIsPledged(isChecked)
    }

    return (
        <div className="pb-8 flex items-center">
            <Switch className="align-middle" checked={isPledged} onCheckedChange={handleToggle} />
            <span className="pl-2">Hide shares held in campaigns</span>
        </div>
    )
}
