import { H1 } from '@/common/ui'

type SnapshotItems = {
    bigNumber?: string
    headline?: string
    text?: string
    icon?: {
        url: string
    }
}[]

export function Snapshot({ snapshot }: { snapshot: SnapshotItems }) {
    const numColumns = snapshot.length % 3 === 0 ? 3 : 2
    return (
        <aside className="grid grid-cols-3 gap-4 my-12" style={{ gridTemplateColumns: `repeat(${numColumns}, 1fr)` }}>
            {snapshot.map((item, index) => {
                const { bigNumber, text, headline, icon } = item
                return (
                    <div
                        key={`${headline}${bigNumber}${text}${index}`}
                        className="bg-orange-100 px-4 py-6 flex flex-col items-center rounded-md"
                    >
                        <div className="flex flex-row items-center justify-center gap-1">
                            {icon && <img src={icon.url} alt={headline} className="w-10 h-10 mb-2" />}
                            {bigNumber && <H1 className="m-0 p-0">{bigNumber}</H1>}
                        </div>
                        {headline && <p className="font-bold">{headline}</p>}
                        {text && <p className="text-center text-balance">{text}</p>}
                    </div>
                )
            })}
        </aside>
    )
}
