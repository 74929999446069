import { Button, H2, Label } from '@/common/ui'
import { Dialog, DialogContent } from '@/common/ui/dialog'
import { FC, useState } from 'react'
import { useContactSupport } from '../userQueries'

type Props = {
    closeModal: () => void
}

export const ContactSupportModal: FC<Props> = ({ closeModal }) => {
    const [message, setMessage] = useState('')
    const [isRunning, setIsRunning] = useState(false)
    const [requestSent, setRequestSent] = useState(false)
    const contactSupport = useContactSupport()

    const sendRequest = () => {
        setIsRunning(true)
        contactSupport.mutate(
            { message },
            {
                onSuccess: () => {
                    setRequestSent(true)
                },
                onSettled: () => {
                    setIsRunning(false)
                },
            },
        )
    }

    return !requestSent ? (
        <>
            <Dialog open={true}>
                <DialogContent
                    onClose={closeModal}
                    className="px-5 py-12 max-h-[90vh] min-h-[50vh] w-[94vw] max-w-[680px] overflow-y-auto xs:p-20"
                >
                    <H2 className="text-center mb-4">Contact Support</H2>
                    <Label className="font-bold">Your message</Label>
                    <textarea
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Please provide details of your request"
                        className="h-40 border rounded-xl p-4"
                    />
                    <Button
                        variant="secondary"
                        size="lg"
                        className="w-44 justify-center"
                        onClick={sendRequest}
                        loading={isRunning}
                    >
                        Send
                    </Button>
                </DialogContent>
            </Dialog>
        </>
    ) : (
        <>
            <Dialog open={true}>
                <DialogContent
                    onClose={closeModal}
                    className="px-5 py-12 max-h-[90vh] min-h-[50vh] w-[94vw] max-w-[680px] overflow-y-auto xs:p-20"
                >
                    <H2 className="text-center mb-4">Thank you</H2>
                    <p className="text-center text-balance text-base mb-8">
                        Your request has been submitted. We’ll be in touch with you shorty.
                    </p>
                    <div className="flex justify-center">
                        <Button variant="secondary" size="lg" className="w-32 justify-center" onClick={closeModal}>
                            Close
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
