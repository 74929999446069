import { OrderT } from '@/common/types'
import { create } from 'zustand'

interface State {
    currentOrder: OrderT | null
    setCurrentOrder: (order: OrderT | null) => void
}

const useOrderReviewStore = create<State>((set) => ({
    currentOrder: null,
    setCurrentOrder: (order: OrderT | null) => set({ currentOrder: order }),
}))

export default useOrderReviewStore
