import Money, { NullMoney } from '@/common/models/money.ts'

const ONE_MILLION = 1e6
const ONE_THOUSAND = 1e3

export default class PrecisionAwarePriceLabel {
    private readonly precision: number
    private readonly value: Money | NullMoney

    constructor(
        amount: Money | null,
        private readonly canUseShorthand: boolean = false,
    ) {
        this.precision = amount ? determinePrecision(amount) : 0
        this.value = amount ?? new NullMoney()
    }

    static of(value: number | null, canUseShorthand: boolean = false): PrecisionAwarePriceLabel {
        return new PrecisionAwarePriceLabel(Money.of(value), canUseShorthand)
    }

    toString() {
        if (this.value?.value === null) {
            return '$ --'
        }
        const num = this.value?.value
        if (this.canUseShorthand) {
            return num >= ONE_MILLION
                ? `$${(num / ONE_MILLION).toFixed(this.precision)}M`
                : num >= ONE_THOUSAND
                  ? `$${(num / ONE_THOUSAND).toFixed(this.precision)}K`
                  : num === 0
                    ? '$0'
                    : `$${num?.toFixed(this.precision)}`
        } else {
            return `$${num?.toFixed(this.precision)}`
        }
    }
}

function determinePrecision(value: Money): number {
    if (value.value < 2.0) {
        return 3
    }
    return 2
}
