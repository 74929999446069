import { CampaignT } from '@/common/types'
import { FC, useEffect, useState } from 'react'
import { Dialog, DialogContent } from '@/common/ui/dialog'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Copy } from 'lucide-react'
import { Button, H2 } from '@/common/ui'
import { analytics } from '@/common/analytics/analytics'

type Props = {
    campaign: CampaignT
    onClose: () => void
}
export const CampaignShareModal: FC<Props> = ({ campaign, onClose }) => {
    const [isCampaignURLCopied, setIsCampaignURLCopied] = useState(false)

    const campaignUrl = () => `https://six-invest.com.au/campaigns/${campaign.campaignId}`

    useEffect(() => {
        if (!isCampaignURLCopied) return
        setTimeout(() => {
            setIsCampaignURLCopied(false)
            analytics.track('CampaignLink Copied', {
                campaign_slug: campaign.campaignId,
                campaign_name: campaign.title,
            })
        }, 2000)
    }, [isCampaignURLCopied, campaign])

    return (
        <>
            <Dialog open={true}>
                <DialogContent
                    onClose={onClose}
                    className="px-5 py-12 max-h-[90vh] min-h-[50vh] w-[94vw] max-w-[680px] overflow-y-auto xs:p-20"
                >
                    <H2 className="text-center mb-4">Share on socials</H2>
                    <p className="text-center text-balance text-base mb-8">
                        Passionate about this campaign? Spread the word with your network, and together, let’s make an
                        impact
                    </p>
                    <div className="py-3 space-x-4 border">
                        <p className="flex font-medium">
                            <span className="mx-4">{campaignUrl()}</span>{' '}
                            <div className="relative">
                                {isCampaignURLCopied && (
                                    <span className="absolute -top-10 -left-4 text-xs text-lime-700 font-semibold bg-white p-2 rounded border">
                                        Copied
                                    </span>
                                )}
                                <CopyToClipboard text={campaignUrl()} onCopy={() => setIsCampaignURLCopied(true)}>
                                    <button className="mx-4">
                                        <Copy />
                                    </button>
                                </CopyToClipboard>
                            </div>
                        </p>
                    </div>
                    <div className="flex justify-center mt-8">
                        <Button onClick={onClose} variant="secondary" size="lg">
                            Close
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
