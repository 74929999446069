import { FC } from 'react'

import { HistoricalPricesRangePreset, PriceDataPoint } from '@/common/types'
import { MONTHS } from '@/common/constants'
import { Maybe } from '@/common/types'
import { SharePriceGraph } from '@/common/ui/SharePriceGraph.tsx'

type Props = {
    data: Maybe<PriceDataPoint[]>
    rangePreset: HistoricalPricesRangePreset
}

// const getDayTime = (datestring: string) => {
//     const date = new Date(datestring)
//     return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')
// }

const getDayMonth = (datestring: string) => {
    const date = new Date(datestring)
    return date.getDate() + ' ' + MONTHS[date.getMonth()]
}

const getMonthYear = (datestring: string) => {
    const date = new Date(datestring)
    return MONTHS[date.getMonth()] + ' ' + date.getFullYear().toString().substring(2)
}

// const getDayMonthYear = (datestring: string) => {
//     const date = new Date(datestring)
//     return date.getDate() + ' ' + MONTHS[date.getMonth()] + ' ' + date.getFullYear()
// }

export const HistoricalPriceChart: FC<Props> = ({ data = [], rangePreset }) => {
    const transformData = (data: any) => {
        return data.map((d: any) => {
            return {
                x: d.date,
                y: d.close,
            }
        })
    }

    const isRangeLessThanYear =
        rangePreset === '1D' ||
        rangePreset === '1W' ||
        rangePreset === '1M' ||
        rangePreset === '6M' ||
        rangePreset === 'YTD' ||
        rangePreset === '1Y'

    return (
        <div>
            <SharePriceGraph
                data={transformData(data)}
                xAxisFormatter={isRangeLessThanYear ? getDayMonth : getMonthYear}
                // TODO: add 1 day preset for custom tooltip
            />
        </div>
    )
}
