import { OrderExpiry, OrderAction, OrderType, CampaignT } from '@/common/types'
import { create } from 'zustand'

interface State {
    orderType?: OrderType
    orderAction: OrderAction
    limitPrice?: number
    orderExpiry: OrderExpiry
    numberOfShares?: number
    instrumentPrice?: number
    approximateInvestmentAmount?: number
    isPledged?: boolean
    campaignsForSecurity?: CampaignT[]
    isPledgedAndCampaignsForSecurity: boolean
    ethicalTags: any[]
    numPositiveTags: number
    numNegativeTags: number
    hasSixthSenseProfile: boolean
    requestId: string | null
}

interface TradeState extends State {
    setOrderAction: (action: OrderAction) => void
    setOrderType: (age: OrderType) => void
    setLimitPrice: (price?: number) => void
    setOrderExpiry: (expiry: OrderExpiry) => void
    setNumberOfShares: (numberOfShares?: number) => void
    setInstrumentPrice: (price: number) => void
    setApproximateInvestmentAmount: (amount?: number) => void
    setRequestId: () => void
    setIsPledged: (isPledged?: boolean) => void
    setCampaignsForSecurity: (value?: CampaignT[]) => void
    setIsPledgedAndCampaignsForSecurity: (value?: boolean) => void
    setEthicalTags: (value?: any[]) => void
    setNumPositiveTags: (number: number) => void
    setNumNegativeTags: (number: number) => void
    setHasSixthSenseProfile: (value: boolean) => void
    resetState: () => void
}

const initialState: State = {
    orderAction: 'BUY',
    orderExpiry: 'VALID_FOR_DAYS_7',
    numberOfShares: undefined,
    approximateInvestmentAmount: undefined,
    limitPrice: undefined,
    isPledged: true,
    campaignsForSecurity: [],
    isPledgedAndCampaignsForSecurity: false,
    ethicalTags: [],
    numPositiveTags: 0,
    numNegativeTags: 0,
    hasSixthSenseProfile: false,
    requestId: crypto.randomUUID(),
}

const useTradeStore = create<TradeState>((set) => ({
    ...initialState,
    setOrderAction: (action: OrderAction) => set(() => ({ orderAction: action })),
    setOrderType: (type: OrderType) => set(() => ({ orderType: type })),
    setLimitPrice: (price?: number) => set(() => ({ limitPrice: price })),
    setOrderExpiry: (expiry: OrderExpiry) => set(() => ({ orderExpiry: expiry })),
    setNumberOfShares: (numberOfShares?: number) => set(() => ({ numberOfShares })),
    setInstrumentPrice: (price: number) => set(() => ({ instrumentPrice: price })),
    setRequestId: () => set(() => ({ requestId: crypto.randomUUID() })),
    setApproximateInvestmentAmount: (amount?: number) => set(() => ({ approximateInvestmentAmount: amount })),
    setIsPledged: (value?: boolean) => set(() => ({ isPledged: value })),
    setCampaignsForSecurity: (value?: CampaignT[]) => set(() => ({ campaignsForSecurity: value })),
    setIsPledgedAndCampaignsForSecurity: (value?: boolean) => set(() => ({ isPledgedAndCampaignsForSecurity: value })),
    setEthicalTags: (value?: any[]) => set(() => ({ ethicalTags: value })),
    setNumPositiveTags: (value: number) => set(() => ({ numPositiveTags: value })),
    setNumNegativeTags: (value: number) => set(() => ({ numNegativeTags: value })),
    setHasSixthSenseProfile: (value: boolean) => set(() => ({ hasSixthSenseProfile: value })),
    resetState: () =>
        set(() => ({
            ...initialState,
            requestId: crypto.randomUUID(),
        })),
}))

export default useTradeStore
