import { FC } from 'react'
import { cn } from '@/common/utils/utils'
import { SecurityAvatar } from './securityAvatar.tsx'

type Props = {
    security: {
        securityName: string
        securityCode: string
    }
    className?: string
    nameClassName?: string
}

// The inconsistency around here is out of hand.
// Company stocks are traded on exchanges, when you buy them you own the securities.
// ETFs are also traded on exchanges, they are managed by a fund, and you can buy shares but the securities
// belong to the fund.
//
// So what's the thing we're really interested in at the UI level? Probably financial products or investments....
export const SecurityHead: FC<Props> = ({ security, nameClassName, className }) => {
    const securityCode = security.securityCode
    return (
        <div className={cn('flex py-2 space-x-3 text-inherit w-max', className)} title={security.securityName}>
            <SecurityAvatar securityCode={securityCode} />
            <div className="flex flex-col">
                <span className="font-semibold">{securityCode}</span>
                <span className={cn('text-xs line-clamp-1', nameClassName)}>{security.securityName}</span>
            </div>
        </div>
    )
}
