import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'

import { cn } from '@/common/utils/utils'
import { Info } from 'lucide-react'

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

interface ContentProps extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> {
    arrowClassName?: string
}
const TooltipContent = React.forwardRef<React.ElementRef<typeof TooltipPrimitive.Content>, ContentProps>(
    ({ className, arrowClassName, sideOffset = 4, ...props }, ref) => (
        <TooltipPrimitive.Content
            ritems-center
            justify-centeref={ref}
            sideOffset={sideOffset}
            className={cn(
                'z-50 overflow-hidden rounded-md border bg-tooltip px-3 py-1.5 text-sm text-tooltip-foreground shadow-md',
                'animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
                className,
            )}
            {...props}
        >
            {props.children}
            <TooltipPrimitive.Arrow className={cn('w-4 h-2 fill-tooltip', arrowClassName)} />
        </TooltipPrimitive.Content>
    ),
)
TooltipContent.displayName = TooltipPrimitive.Content.displayName

const InfoIconTooltip: React.FC<{ children: React.ReactNode; size?: number }> = ({ children, size = 16 }) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Info size={size} strokeWidth={2} />
                </TooltipTrigger>
                <TooltipContent className="ml-20 xs:ml-10" arrowClassName="-mt-1">
                    <p className="w-60">{children}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider, InfoIconTooltip }
