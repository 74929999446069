import { analytics } from '@/common/analytics/analytics'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/common/ui/tabs'
import { Copy } from 'lucide-react'
import { FC, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

type PayIDDetails = {
    payId: string
    payIdName: string
    bankAccountName: string
    bankAccountNumber: string
    bsb: string
}

type PayIdDetailsProps = {
    payIdDetails: PayIDDetails
}

type TabProps = {
    label: string
    value: string
}
const Tab: FC<TabProps> = ({ label, value }) => {
    return (
        <TabsTrigger
            value={value}
            className="data-[state=active]:font-semibold text-foreground w-full py-3 rounded-b-none data-[state=active]:bg-secondary-light"
        >
            {label}
        </TabsTrigger>
    )
}

export default function DepositOptions(props: PayIdDetailsProps) {
    const defaultTab = props.payIdDetails.payId ? 'payid' : 'accounts'
    return (
        <Tabs defaultValue={defaultTab} className="mt-4">
            <TabsList className="p-0 h-auto bg-background flex justify-center">
                <Tab label="PayID" value="payid" />
                <Tab label="Bank Account" value="account" />
            </TabsList>
            <div className="h-[2px] bg-primary-light" />
            <TabsContent tabIndex={-1} className="pt-4" value="payid">
                <PayIDTab payIdDetails={props.payIdDetails} />
            </TabsContent>
            <TabsContent tabIndex={-1} className="pt-4" value="account">
                <BankAccountDetailsTab payIdDetails={props.payIdDetails} />
            </TabsContent>
        </Tabs>
    )
}

function PayIDTab({ payIdDetails }: PayIdDetailsProps) {
    const [isPayIdCopied, setIsPayIdCopied] = useState(false)

    const handlePayIdCopied = () => {
        setIsPayIdCopied(true)
        analytics.track('PayIdAddress Copied')
    }

    if (!payIdDetails.payId)
        return <p>Your PayID is being setup, please use bank account to deposit, or contact support.</p>

    return (
        <div className="px-5">
            <div className="py-3 border-b">
                <label className="font-light">Your unique PayID email</label>
                <p className="flex space-x-3 font-medium">
                    <span>{payIdDetails.payId ?? 'Your PayID is being setup'}</span>{' '}
                    {payIdDetails.payId && (
                        <div className="relative">
                            {isPayIdCopied && (
                                <span className="absolute -top-10 -left-4 text-xs text-lime-700 font-semibold bg-white p-2 rounded border">
                                    Copied
                                </span>
                            )}
                            <CopyToClipboard text={payIdDetails.payId} onCopy={handlePayIdCopied}>
                                <button>
                                    <Copy />
                                </button>
                            </CopyToClipboard>
                        </div>
                    )}
                </p>
            </div>
            <div className="py-3 border-b">
                <label className="font-light">Account name</label>
                <p className="flex space-x-3 font-medium">{payIdDetails.payIdName}</p>
            </div>
        </div>
    )
}

function BankAccountDetailsTab({ payIdDetails }: PayIdDetailsProps) {
    return (
        <div className="px-5">
            <div className="py-3 border-b">
                <label className="font-light">Bank account name</label>
                <p className="flex space-x-3 font-medium">
                    <span>{payIdDetails.bankAccountName}</span>{' '}
                </p>
            </div>

            <div className="py-3 border-b">
                <label className="font-light">Bank account number</label>
                <p className="flex space-x-3 font-medium">{payIdDetails.bankAccountNumber}</p>
            </div>
            <div className="py-3 border-b">
                <label className="font-light">BSB</label>
                <p className="flex space-x-3 font-medium">{payIdDetails.bsb}</p>
            </div>
        </div>
    )
}
