import { Button, H2 } from '@/common/ui'
import { useSubscribeCampaign, useUnsubscribeCampaign } from '@/campaigns/campaignsQueries'
import { ReactNode, useEffect, useState } from 'react'
import { CampaignT } from '@/common/types'
import { Heart, Share2 } from 'lucide-react'
import { CampaignShareModal } from './CampaignShareModal'
import clsx from 'clsx'
import { PledgedHoldings } from '@/portfolio/PledgedHoldings'
import { analytics } from '@/common/analytics/analytics'
import { CampaignStatusPill } from './CampaignStatusPill'
import { ExternalLink } from 'lucide-react'
import { CountdownTimer } from './CountdownTimer'
import { SecurityAvatar } from '@/securities/securityAvatar.tsx'
import { cn } from '@/common/utils/utils'
import { useListUserPledges } from '@/trading/tradingQueries.ts'
import { getPledgeBySecurityCode } from '@/campaigns/campaignsApi.ts'

export type CampaignWithSubscription = CampaignT & { isSubscribed?: boolean; pledged?: boolean }

type Props = {
    campaign: CampaignWithSubscription
    handleOrder: () => void
    layout?: 'default' | 'expanded'
}

export const Campaign = ({ campaign, handleOrder, layout = 'default' }: Props) => {
    const subscribeCampaign = useSubscribeCampaign()
    const unsubscribeCampaign = useUnsubscribeCampaign()
    const { data: pledges } = useListUserPledges()

    const [subscribed, setSubscribed] = useState<boolean>(campaign.isSubscribed ?? false)
    const [shared, setShared] = useState<boolean>(false)
    const [followers, setFollowers] = useState<number>(campaign.followers)
    const [allPledges, setAllPledges] = useState<{ [key: string]: number }>({})
    const [userPledgedSecurities, setPledgedSecurities] = useState<{ securityCode: string; securityName: string }[]>([])

    const handleFollow = () => {
        setSubscribed(true)
        setFollowers(followers + 1)
        subscribeCampaign.mutate(campaign.campaignId)
        analytics.track('Campaign Followed', {
            campaign_slug: campaign.campaignId,
            campaign_name: campaign.title,
            source_page: 'campaign',
        })
    }

    const handleUnfollow = () => {
        setSubscribed(false)
        setFollowers(followers - 1)
        unsubscribeCampaign.mutate(campaign.campaignId)
        analytics.track('Campaign Unfollowed', {
            campaign_slug: campaign.campaignId,
            campaign_name: campaign.title,
            source_page: 'campaign',
        })
    }

    const closeShareModal = () => {
        setShared(false)
    }

    const toggleFollow = () => {
        if (subscribed) {
            handleUnfollow()
        } else {
            handleFollow()
        }
    }

    const handleShare = () => {
        setShared(true)
        analytics.track('CampaignShareButton Clicked', {
            campaign_slug: campaign.campaignId,
            campaign_name: campaign.title,
            source_page: 'campaign',
        })
    }

    useEffect(() => {
        setSubscribed(campaign.isSubscribed ?? false)
    }, [campaign.isSubscribed])

    useEffect(() => {
        const securityCodeToCompanyNameMapping = campaign.targetCompaniesMetadata?.map((company) => {
            return {
                securityCode: company.code,
                securityName: company.name,
            }
        })

        const campaignPledges = pledges
            ?.filter((pledge) => campaign.targetCompaniesAsxCodes.includes(pledge.securityCode))
            .map((pledge) => {
                return {
                    securityCode: pledge.securityCode,
                    securityName:
                        securityCodeToCompanyNameMapping?.find(
                            (company) => company.securityCode === pledge.securityCode,
                        )?.securityName ?? 'Unknown',
                }
            })
        setPledgedSecurities(campaignPledges || [])
    }, [campaign, pledges])

    useEffect(() => {
        if (campaign.targetCompaniesAsxCodes.length > 0) {
            const fetchPledges = async (securityCodes: string[]) => {
                const pledges: { [key: string]: number } = {}
                await Promise.all(
                    securityCodes.map(async (code) => {
                        try {
                            pledges[`${code}`] = await getPledgeBySecurityCode(campaign.campaignId, code)
                        } catch (e) {
                            pledges[`${code}`] = 0
                        }
                    }),
                )
                setAllPledges(pledges)
            }
            fetchPledges(campaign.targetCompaniesAsxCodes)
        }
    }, [campaign])

    const CampaignHero = () => {
        return (
            <a href={`/campaigns/${campaign.campaignId}`} className="contents">
                <div
                    className={cn(
                        `relative bg-cover bg-center min-h-[192px] w-full`,
                        layout === 'expanded' ? 'md:w-[55%]' : 'md:w-full',
                    )}
                    style={{ backgroundImage: `url('${campaign.heroImage.url}')` }}
                >
                    <div
                        className={cn(
                            'absolute w-full flex items-end',
                            layout === 'default' ? 'max-w-[298px] bottom-0' : 'top-6',
                        )}
                    >
                        {campaign.countdownDate && (
                            <CountdownTimer countdownDate={campaign.countdownDate} className="px-6 pb-6" />
                        )}
                    </div>
                </div>
            </a>
        )
    }

    const CampaignDetails = () => {
        return (
            <div className="p-8 pb-0">
                {campaign.status && <CampaignStatusPill status={campaign.status} campaignId={campaign.campaignId} />}
                <a
                    className="text-black"
                    href={`/campaigns/${campaign.campaignId}`}
                    aria-label={`View ${campaign.title} campaign`}
                >
                    <H2>{campaign.title}</H2>
                </a>
                <div className="flex justify-between py-4 mb-2 mt-2 flex-col gap-2 xs:flex-row">
                    <Button variant="ghost" className="hover:bg-transparent px-4 md:px-0" onClick={toggleFollow}>
                        <Heart className={clsx({ 'text-destructive fill-destructive': subscribed })} />
                        <span>{followers} followers</span>
                    </Button>
                    <Button variant="outline" className="hover:bg-transparent" onClick={handleShare}>
                        <Share2 />
                        Share
                    </Button>
                </div>
                {shared && <CampaignShareModal campaign={campaign} onClose={closeShareModal} />}
                {campaign.description && (
                    <p className="pb-6">
                        <p className="mb-2">{campaign.description}</p>
                        <a className="text-primary" href={`/campaigns/${campaign.campaignId}`}>
                            <Button size="sm" variant="tertiary" className="border-0 py-3 px-5 text-xs">
                                Read more
                            </Button>
                        </a>
                    </p>
                )}
            </div>
        )
    }

    const CampaignSecurities = () => {
        return (
            <>
                {userPledgedSecurities.length > 0 && (
                    <div className="mb-2">
                        <PledgedHoldings pledges={userPledgedSecurities} />
                    </div>
                )}
                <div className="flex flex-col gap-1 mb-4">
                    {Object.keys(allPledges)
                        .sort()
                        .map((code) => {
                            if (allPledges[code] === 0) return null
                            return (
                                <div className="flex items-center mb-1">
                                    <div className="min-w-[30%] text-right mr-1 flex items-center">
                                        <SecurityAvatar className="mr-1" securityCode={code} height={8} width={8} />
                                        <span className="text-[13px]">{allPledges[code]}/100</span>
                                    </div>
                                    <div className="relative w-[60%] h-[8px] rounded-full overflow-hidden">
                                        <style>{`.${code} { --bar-width: ${Math.min(allPledges[code], 100)}%; }`}</style>
                                        <div className={`absolute bg-[#F0F0F0] w-full h-full ${code}`}>
                                            <div className={`bg-electric-lime w-[--bar-width] h-full`}></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}{' '}
                </div>
            </>
        )
    }

    const CampaignActions = () => {
        return (
            <div>
                {campaign.targetCompaniesAsxCodes.length > 0 && (
                    <>
                        <Button onClick={handleOrder} tabIndex={-1} variant="secondary" fullWidth>
                            Buy Shares
                        </Button>
                        <div className="mt-3 text-center">
                            <a
                                href="https://www.six-invest.com.au/agency-agreement"
                                target="_blank"
                                className="text-xs text-accent-foreground flex justify-center items-center"
                            >
                                Pledge shares I own outside of SIX
                                <ExternalLink className="ml-1 h-3 w-3" />
                            </a>
                        </div>
                    </>
                )}
            </div>
        )
    }

    const ArticleLayout = ({ children }: { children: ReactNode }) => {
        return (
            <article
                key={campaign.campaignId}
                className={cn(
                    'w-full mb-10 rounded-md border flex overflow-hidden justify-between flex-col',
                    layout === 'expanded' ? 'md:flex-row-reverse' : 'md:flex-col',
                )}
            >
                {children}
            </article>
        )
    }

    if (layout === 'expanded')
        return (
            <ArticleLayout>
                <CampaignHero />
                <div className="w-full md:w-[45%]">
                    <CampaignDetails />
                    <div className="px-6 pb-8">
                        <CampaignSecurities />
                        <CampaignActions />
                    </div>
                </div>
            </ArticleLayout>
        )

    return (
        <ArticleLayout>
            <div>
                <CampaignHero />
                <div className="md:w-full">
                    <CampaignDetails />
                </div>
            </div>
            <div>
                <div className="px-6 pb-8">
                    <CampaignSecurities />
                    <CampaignActions />
                </div>
            </div>
        </ArticleLayout>
    )
}
