import { OrderAction } from '@/common/types'
import { create } from 'zustand'

interface State {
    securityCodes: string[] | null
    setSecurityCodes: (securityCodes: string[] | null) => void
    orderAction: OrderAction | null
    setOrderAction: (orderAction: OrderAction | null) => void
}

const useTradeModalStore = create<State>((set) => ({
    securityCodes: null,
    setSecurityCodes: (securityCodes: string[] | null) => set({ securityCodes }),
    orderAction: null,
    setOrderAction: (orderAction: OrderAction | null) => set({ orderAction }),
}))

export default useTradeModalStore
