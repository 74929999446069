import * as yup from 'yup'
import { useNavigate } from 'react-router'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Helmet } from 'react-helmet'

import { Onboarding } from '@/onboarding/OnboardingPageTemplate'
import { useAcceptPolicy, useFetchUsagePolicy, useUpdateSurveyData } from '@/user/userQueries'
import { useEffect, useMemo, useRef } from 'react'
import { APP, Errors } from '@/common/strings'
import { APP_ROUTES, USAGE_POLICIES } from '@/common/constants'
import { SelectField } from './SelectField'
import useUserStore from '@/user/userStore'
import { occupations } from './occupations'
import { frequencyOptions } from './frequencyOptions'
import { amountOptions } from './amountOptions'
import { goalOptions } from './goalOptions'
import { Checkbox } from '@/common/ui/checkbox'
import { analytics } from '@/common/analytics/analytics'
import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { toast } from 'sonner'

export type SurveyT = {
    occupation: string
    investmentPurpose: string
    expectedTradeFrequency: string
    expectedTradeAmount: string
}

const schema = yup
    .object({
        occupation: yup.string().required('Please enter occupation').min(2, 'Please enter valid occupation'),
        investmentPurpose: yup.string().required('Please select an option'),
        expectedTradeFrequency: yup.string().required('Please select an option'),
        expectedTradeAmount: yup.string().required('Please select an option'),
    })
    .required()

export function Survey() {
    const { user, setUser } = useUserStore()
    const navigate = useNavigate()
    const updateSurveyData = useUpdateSurveyData()
    const acceptPolicy = useAcceptPolicy()
    const {
        data: finclearTerms,
        isError: finclearError,
        isFetched: finclearFetched,
    } = useFetchUsagePolicy(USAGE_POLICIES.FINCLEAR_TERMS)

    const {
        handleSubmit,
        setValue,
        trigger,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
    })

    const showErrorToastRef = useRef(false)

    const onSubmit = (data: SurveyT) => {
        updateSurveyData.mutate({
            occupation: data.occupation,
            investmentPurpose: data.investmentPurpose,
            expectedTradeFrequency: data.expectedTradeFrequency,
            expectedTradeAmount: data.expectedTradeAmount,
        })
        if (!finclearTerms?.versionId) {
            toast.error(Errors.unknown)
            return
        }
        acceptPolicy.mutate(finclearTerms?.versionId)
        if (!user) return
        setUser({ ...user, occupation: data.occupation })
    }

    const prefillForm = () => {
        setValue('occupation', user?.occupation || '')
        setValue('investmentPurpose', user?.survey?.investmentPurpose || '')
        setValue('expectedTradeFrequency', user?.survey?.expectedTradeFrequency || '')
        setValue('expectedTradeAmount', user?.survey?.expectedTradeAmount || '')
    }

    const trackAnalytics = useMemo(() => {
        return () => {
            analytics.track('onboarding_survey_submitted', {
                occupation: user?.occupation || '',
                investmentPurpose: user?.survey?.investmentPurpose || '',
                expectedTradeFrequency: user?.survey?.expectedTradeFrequency || '',
                expectedTradeAmount: user?.survey?.expectedTradeAmount || '',
            })
            navigate(APP_ROUTES.KYC_INIT)
        }
    }, [navigate, user])

    useEffect(() => {
        if (updateSurveyData.isSuccess && acceptPolicy.isSuccess) {
            trackAnalytics()
        }
    }, [updateSurveyData.isSuccess, acceptPolicy.isSuccess, trackAnalytics])

    useEffect(() => {
        prefillForm()
    }, [user, setValue])

    useEffect(() => {
        if ((finclearFetched && !finclearTerms?.disclaimer) || finclearError) {
            if (!showErrorToastRef.current) {
                toast.error(Errors.unknown)
                showErrorToastRef.current = true
            }
        }
    }, [finclearFetched, finclearError, finclearTerms])

    if (!finclearTerms?.disclaimer) return <FullScreenLoader dark />

    return (
        <>
            <Helmet>
                <title>Almost there | {APP.title}</title>
            </Helmet>
            <Onboarding
                isLoading={updateSurveyData.isPending}
                title="Almost there!"
                cta="Setup trading account"
                onSubmit={handleSubmit(onSubmit)}
            >
                <p className="hidden xs:block text-center text-balance mb-2">
                    We just need a few more details to get your trading account set up.
                </p>
                <SelectField
                    label="Occupation"
                    fieldName="occupation"
                    control={control}
                    setValue={setValue}
                    trigger={trigger}
                    error={errors.occupation?.message ?? ''}
                    items={occupations}
                    defaultValue={user?.occupation}
                    className="text-xs xs:text-sm"
                />
                <SelectField
                    label="What is the main goal for this account with SIX?"
                    fieldName="investmentPurpose"
                    control={control}
                    setValue={setValue}
                    trigger={trigger}
                    error={errors.investmentPurpose?.message ?? ''}
                    items={goalOptions}
                    className="text-xs xs:text-sm"
                />
                <SelectField
                    label="How often do you intend to trade?"
                    fieldName="expectedTradeFrequency"
                    control={control}
                    setValue={setValue}
                    trigger={trigger}
                    error={errors.expectedTradeFrequency?.message ?? ''}
                    items={frequencyOptions}
                    className="text-xs xs:text-sm"
                />
                <SelectField
                    label="How much do you think you will trade each time?"
                    fieldName="expectedTradeAmount"
                    control={control}
                    setValue={setValue}
                    trigger={trigger}
                    error={errors.expectedTradeAmount?.message ?? ''}
                    items={amountOptions}
                    className="text-xs xs:text-sm"
                />
                <div className="flex leading-none space-x-3 mt-2 items-center">
                    <Checkbox id="terms" required />
                    <label
                        htmlFor="terms"
                        className="text-xs leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                        <span dangerouslySetInnerHTML={{ __html: finclearTerms.disclaimer }} />
                    </label>
                </div>
            </Onboarding>
        </>
    )
}
