export function EthicalTags({ tags }: { tags: any }) {
    if (!tags) return null

    const order = [
        'Renewable energy',
        'Electric vehicles',
        'Water efficiency',
        'Nutritious foods',
        'Recycling and waste management',
        'Healthcare',
        'Education',
        'Sustainable buildings',
        'Energy transition materials',
    ]

    const orderedTags = tags.sort((a: any, b: any) => {
        const tagA = a.tag.replace(/[^a-zA-Z ]/g, '').trim()
        const tagB = b.tag.replace(/[^a-zA-Z ]/g, '').trim()
        return order.indexOf(tagA) - order.indexOf(tagB)
    })

    return orderedTags.map((tag: any) => (
        <div
            className={`px-3 py-1.5 max-w-fit rounded-2xl backdrop-blur-[10px] items-center gap-1 ${tag.isTagPositive ? 'bg-lime-50' : 'bg-red-50'}`}
        >
            <div className="text-black text-sm">{tag.tag}</div>
        </div>
    ))
}
