import { H2 } from '@/common/ui'
import { EthicalTags } from '@/common/ui/EthicalTags'
import { ListItem } from '@/common/ui/ListItem'
import { UnorderedList } from '@/common/ui/UnorderedList'
import { OnThisPage } from '@/research/OnThisPage'
import { Snapshot } from '@/research/Snapshot'
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS, Document, Text } from '@contentful/rich-text-types'
import React, { ReactNode } from 'react'
import { CampaignT } from '@/common/types.ts'
import { ProfileActiveCampaign } from '@/securities/profileActiveCampaign.tsx'
import { analytics } from '@/common/analytics/analytics.ts'
import { ProfilePastCampaign } from '@/securities/profilePastCampaign.tsx'
import { useListUserPledges } from '@/trading/tradingQueries.ts'

function Author() {
    const SIXIcon = (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Pictoral_Lime 3" clip-path="url(#clip0_7289_63752)">
                <path
                    id="Vector"
                    d="M20.4886 0H0V0.167198C0 10.0959 5.31871 19.2242 13.8856 24.2437C22.4524 29.2632 27.5114 37.8685 27.5114 48H48V47.8328C48 37.9041 42.6813 28.7758 34.1144 23.7563C25.9638 18.9787 20.4886 10.1279 20.4886 0Z"
                    fill="#CFF660"
                />
                <path
                    id="Vector_2"
                    d="M35.171 0H47.9964V3.05225C47.9964 7.282 44.5633 10.7149 40.3332 10.7149H27.5078V7.66264C27.5078 3.43289 30.941 0 35.171 0Z"
                    fill="#CFF660"
                />
                <path
                    id="Vector_3"
                    d="M7.66321 37.2851H20.4886V40.3373C20.4886 44.5671 17.0554 48 12.8254 48H0V44.9477C0 40.718 3.43315 37.2851 7.66321 37.2851Z"
                    fill="#CFF660"
                />
            </g>
            <defs>
                <clipPath id="clip0_7289_63752">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )

    return (
        <div className="justify-start items-start gap-4 inline-flex">
            {SIXIcon}
            <div className="self-stretch flex-col justify-center items-start gap-2 inline-flex">
                <div className="text-black text-base font-semibold">SIX ESG analyst</div>
                <div className="text-gray-700 text-base font-light">Last updated: October 23, 2024</div>
            </div>
        </div>
    )
}

function addBlankTargetAndAnalyticsTrackingToHTMLAnchorElements(children: ReactNode, securityCode: string) {
    return React.Children.map(children, (child: any) => {
        if (child && child.type === 'a') {
            return React.cloneElement(child, {
                target: '_blank',
                onClick: () => {
                    analytics.track('EthicalProfileLink Clicked', {
                        security_code: securityCode,
                        link: child.props.href,
                    })
                },
                ...child,
            })
        }
        return child
    })
}

export function EthicalProfile({
    data,
    campaignsForSecurity,
    securityCode,
}: {
    data: any
    campaignsForSecurity: CampaignT[]
    securityCode: string
}) {
    const { data: pledges } = useListUserPledges()

    if (!data?.content) return 'Our team is working on this profile. Please check back later.'

    const activeCampaigns = campaignsForSecurity.filter((campaign) => campaign.status.label !== 'Campaign Finished')
    const pastCampaigns = campaignsForSecurity.filter((campaign) => campaign.status.label === 'Campaign Finished')

    const options: Options = {
        renderNode: {
            // @ts-ignore
            [BLOCKS.HEADING_2]: (node: Block | Inline, children: ReactNode) => {
                return (
                    <div id={`${(node.content[0] as Text).value}`}>
                        <p className="text-black text-base font-semibold mt-12 first:mt-0 mb-2">
                            {addBlankTargetAndAnalyticsTrackingToHTMLAnchorElements(children, securityCode)}
                        </p>
                    </div>
                )
            },
            // @ts-ignore
            [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => (
                <p className="mb-4">{addBlankTargetAndAnalyticsTrackingToHTMLAnchorElements(children, securityCode)}</p>
            ),
            // @ts-ignore
            [BLOCKS.UL_LIST]: (node: Block | Inline, children: ReactNode) => (
                <UnorderedList>
                    {addBlankTargetAndAnalyticsTrackingToHTMLAnchorElements(children, securityCode)}
                </UnorderedList>
            ),
            // @ts-ignore
            [BLOCKS.LIST_ITEM]: (node: Block | Inline, children: ReactNode) => (
                <ListItem>{addBlankTargetAndAnalyticsTrackingToHTMLAnchorElements(children, securityCode)}</ListItem>
            ),
            // @ts-ignore
            [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline) => {
                const entry = data.content.links.entries.block.find(
                    (entry: any) => entry.sys.id === node.data.target.sys.id,
                )
                const snapshot = entry.itemsCollection.items
                return <Snapshot snapshot={snapshot} />
            },
        },
    }

    return (
        <div className="max-w-[1200px] grid grid-cols-1 lg:grid-cols-[2fr_1fr] gap-16">
            <div className="row-start-2 lg:row-auto">
                <H2>Ethical Profile</H2>
                <div className="flex-col justify-start mb-6 items-start gap-6 flex">
                    <Author />
                </div>
                <div className="mb-10 justify-start items-start gap-2 inline-flex flex-wrap">
                    <EthicalTags tags={data.tagsCollection.items} />
                </div>
                {data.sixthSense && (
                    <div className="px-6 mb-10 py-8 bg-[#f8f8f8] rounded-lg">
                        <H2>The SIXth sense</H2>
                        <div className="mb-4">
                            {documentToReactComponents(data.sixthSense.json as Document, options)}
                        </div>
                    </div>
                )}

                {activeCampaigns.length > 0 && (
                    <>
                        <H2>Active campaigns ({activeCampaigns.length})</H2>
                        {activeCampaigns.map((campaign) => (
                            <ProfileActiveCampaign
                                campaign={campaign}
                                securityCode={securityCode}
                                isPledged={
                                    pledges !== undefined &&
                                    pledges?.filter(
                                        (pledge) =>
                                            pledge.securityCode === securityCode &&
                                            pledge.campaignId === campaign.campaignId,
                                    ).length > 0
                                }
                                otherPledgedSecurityCodes={
                                    pledges
                                        ?.filter(
                                            (pledge) =>
                                                pledge.securityCode !== securityCode &&
                                                pledge.campaignId === campaign.campaignId,
                                        )
                                        .map((other) => other.securityCode) || []
                                }
                            />
                        ))}
                    </>
                )}

                {data.content.json && (
                    <>
                        <H2>The details</H2>
                        {documentToReactComponents(data.content.json as Document, options)}
                    </>
                )}

                {pastCampaigns.length > 0 && (
                    <>
                        <H2>Past campaigns ({pastCampaigns.length})</H2>
                        {pastCampaigns.map((campaign) => (
                            <ProfilePastCampaign securityCode={securityCode} campaign={campaign} />
                        ))}
                    </>
                )}

                <div className="flex-col mt-10 justify-start items-start gap-2 inline-flex">
                    <div className="text-black text-sm font-bold">Disclaimer</div>
                    <div className="self-stretch text-black text-sm font-normal">
                        This information has been researched by the SIX team based on the most recently available
                        information. We've made every effort to ensure this information is accurate at the time of
                        publishing but we do not assume responsibility or liability for any errors or omissions in this
                        information. Always complete your own research before making financial decisions
                    </div>
                </div>
            </div>

            <OnThisPage document={data.content.json as Document} />
        </div>
    )
}
