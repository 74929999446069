import { Check } from 'lucide-react'

import { Button, H2, H3 } from '@/common/ui'
import { InstrumentT } from '@/common/types'
import { FC } from 'react'
import { ReviewItems } from './revewItems'
import { SupportedCampaigns } from '@/campaigns/supportedCampaigns.tsx'
import useTradeStore from '@/trading/tradeStore.ts'

type Props = {
    instrument: InstrumentT
    onClose: () => void
}
export const OrderConfirmation: FC<Props> = ({ instrument, onClose }) => {
    const action = useTradeStore((state) => state.orderAction)

    return (
        <div>
            <span className="w-14 h-14 bg-secondary rounded-full mx-auto flex justify-center items-center">
                <Check />
            </span>
            <H2 className="text-center pt-4">Thanks for placing your {action.toLowerCase()} order</H2>
            <H3 className="text-center pt-6 pb-2">Order Summary</H3>
            <ReviewItems instrument={instrument} />
            <SupportedCampaigns />

            <div className="pt-1">
                <Button onClick={onClose} className="mx-auto block mt-8" size="lg" variant="secondary">
                    Close
                </Button>
            </div>
        </div>
    )
}
