import { cn } from '../utils/utils'

export function PriceDiff({ value, label }: { value: number; label: string }) {
    return (
        <>
            <span
                className={cn('size-0 border-transparent border-[7px] self-baseline', {
                    'border-b-success': value > 0,
                    'border-t-destructive mt-1': value < 0, // Adjust margin as needed
                })}
            />
            <div
                className={cn('text-primary leading-snug ml-1', {
                    'text-success': value > 0,
                    'text-destructive': value < 0,
                })}
            >
                {label}
            </div>
        </>
    )
}
