import { Logo } from '../assets/svg'
import { Facebook } from '../assets/svg/facebook'
import { Flags } from '../assets/svg/flags'
import { Instagram } from '../assets/svg/instagram'
import { LinkedIn } from '../assets/svg/linkedIn'

export function Footer() {
    return (
        <footer className="mt-40 mb-20">
            <div className="w-full bg-[#2B4C41] rounded-lg rounded-br-[100px] text-background p-12 grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div>
                    <div className="flex flex-col gap-2">
                        <a
                            className="text-secondary-light"
                            href="https://cdn.prod.website-files.com/659642fe2d32d0e5e8e622ae/667e4952d3a76e4139137bd2_FINAL%20Sustainable%20Investment%20Exchange%20Privacy%20Policy%20June%202024.pdf"
                            target="_blank"
                        >
                            Privacy Policy
                        </a>
                        <a
                            className="text-secondary-light"
                            href="https://cdn.prod.website-files.com/659642fe2d32d0e5e8e622ae/667e49e623e9c8b040db3352_Updated%20Terms%20and%20Conditions%20June%202024.pdf"
                            target="_blank"
                        >
                            Terms of Service
                        </a>
                        <a
                            className="text-secondary-light"
                            href="https://cdn.prod.website-files.com/659642fe2d32d0e5e8e622ae/666ba18923f4d0d62b578470_Sustainable%20Investment%20Exchange_Financial%20Services%20Guide_FINAL%20June%2024.docx%20(1).pdf"
                            target="_blank"
                        >
                            Financial Services Guide
                        </a>
                        <a
                            className="text-secondary-light"
                            href="https://cdn.prod.website-files.com/659642fe2d32d0e5e8e622ae/65af5da8dea57129e1855c7f_Sustainable%20Investment%20Exchange%20-%20Complaints%20Handling%20Policy.pdf"
                            target="_blank"
                        >
                            Complaints Handling Policy
                        </a>
                    </div>

                    <div className="flex h-8 gap-2 mt-8">
                        <a
                            className="text-secondary-light"
                            href="https://www.facebook.com/six.invest.au"
                            target="_blank"
                        >
                            <Facebook />
                        </a>
                        <a
                            className="text-secondary-light"
                            href="https://www.instagram.com/six.invest.au/"
                            target="_blank"
                        >
                            <Instagram />
                        </a>
                        <a
                            className="text-secondary-light"
                            href="https://www.linkedin.com/company/sustainable-investment-exchange/"
                            target="_blank"
                        >
                            <LinkedIn />
                        </a>
                    </div>
                </div>
                <div>
                    <p className="mb-8 text-white">
                        We acknowledge the Traditional Owners of the Country on which we live and work and we pay our
                        respects to Elders past and present. We recognise Aboriginal and Torres Strait Islander peoples'
                        continuing connection to lands, waters and communities. Always was, always will be, Aboriginal
                        Land.
                    </p>
                    <Flags />
                </div>
            </div>
            <div className="mt-8 grid grid-cols-1 lg:grid-cols-[auto_1fr] gap-4 lg:gap-8">
                <Logo width={100} height={30} />
                <p className="text-sm">
                    Sustainable Investment Exchange Pty Ltd (ABN 61 669 461 465, AFSL no. 557092). Any information about
                    financial products and financial services available from or through Sustainable Investment Exchange
                    is general information only and does not take into account your personal objectives, financial
                    situation or needs.
                </p>
            </div>
        </footer>
    )
}
