import { cn } from '@/common/utils/utils'

type Props = {
    children: React.ReactNode
    className?: string
}

export function ListItem({ children, className }: Props) {
    return <li className={cn('mb-2 last:mb-0', className)}>{children}</li>
}
