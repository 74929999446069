import React from 'react'
import { cn } from '@/common/utils/utils'
import { InputError } from './InputError'
import { Label } from '@/common/ui/Label'

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string
    error?: string
    isVertical?: boolean
    prefixIcon?: React.ReactNode
}

export const LabelInput = React.forwardRef<HTMLInputElement, Props>(
    ({ className, isVertical = true, required, id, label, error, prefixIcon, ...props }: Props, ref) => {
        const input = (
            <input
                ref={ref}
                id={id}
                required={required}
                className={cn('w-full rounded-full border border-gray-300 py-2 px-4', className, prefixIcon && 'pl-7')}
                {...props}
            />
        )
        return (
            <div className={`flex ${isVertical ? 'flex-col' : 'flex-row justify-between items-center'} w-full`}>
                {label && (
                    <Label className="text-sm" htmlFor={id}>
                        {label}
                    </Label>
                )}
                {prefixIcon ? (
                    <div className="relative w-1/2">
                        <div className="pointer-events-none absolute inset-y-0 -left-7 flex items-center pl-3">
                            {prefixIcon}
                        </div>
                        {input}
                    </div>
                ) : (
                    input
                )}
                <InputError message={error} />
            </div>
        )
    },
)
