import {
    ColumnDef,
    SortingState,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/common/ui/table'
import { useState } from 'react'
import { ChevronDown, ChevronsUpDown, ChevronUp } from 'lucide-react'

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[]
    data: TData[]
    noDataMessage?: string
    classNameHeader?: string
    classNameCell?: string
}

export function DataTable<TData, TValue>({
    columns,
    data,
    noDataMessage = 'No results',
    classNameHeader,
    classNameCell,
}: DataTableProps<TData, TValue>) {
    const [sorting, setSorting] = useState<SortingState>([])
    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
    })

    return (
        <div className="rounded-md overflow-x-auto">
            <Table className="min-w-full">
                <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header, index) => {
                                const isSortable = header.column.getCanSort()
                                const sortingState = header.column.getIsSorted()
                                const isFirstColumn = index === 0

                                return (
                                    <TableHead
                                        key={header.id}
                                        className={`${classNameHeader} ${isSortable ? 'cursor-pointer' : ''} ${
                                            isFirstColumn ? 'sticky left-0 bg-white z-10' : ''
                                        }`}
                                        onClick={isSortable ? header.column.getToggleSortingHandler() : undefined}
                                    >
                                        {header.isPlaceholder ? null : (
                                            <div className="flex items-center">
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {sortingState === 'asc' && <ChevronUp className="w-4 h-4 relative" />}
                                                {sortingState === 'desc' && (
                                                    <ChevronDown className="w-4 h-4 relative" />
                                                )}
                                                {header.column.getCanSort() &&
                                                    sortingState !== 'asc' &&
                                                    sortingState !== 'desc' && (
                                                        <ChevronsUpDown className="w-4 h-4 relative" />
                                                    )}
                                            </div>
                                        )}
                                    </TableHead>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableHeader>
                <TableBody className="[&_tr:last-child]:border-b">
                    {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => (
                            <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                                {row.getVisibleCells().map((cell, index) => (
                                    <TableCell
                                        width={cell.column.getSize()}
                                        className={`py-1 px-4 ${classNameCell} ${
                                            index === 0 ? 'sticky left-0 bg-white z-10' : ''
                                        }`}
                                        key={cell.id}
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length} className="h-24 text-center">
                                {noDataMessage}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )
}
