import { InstrumentT } from '@/common/types'
import { H2 } from '@/common/ui'
import { Button } from '../common/ui/button'
import { useState } from 'react'
import { SecuritySelectButtons } from './SecuritySelectButtons'

type Props = {
    instruments: InstrumentT[]
    pledges?: { [key: string]: number } | null
    onSelectionMade: (instrument: InstrumentT) => void
}
export const TradeSelect = ({ instruments, pledges, onSelectionMade }: Props) => {
    const [selectedInstrument, setSelectedInstrument] = useState<InstrumentT | null>(instruments[0] || null)

    return (
        <div>
            <H2 className="text-center mb-4">Please select a company below</H2>
            <p className="text-center text-balance text-sm mb-8">
                Choose from the companies listed below to join your community in leveraging your shares as a vote for
                change! We require at least 100 shareholders to file a resolution for each.
            </p>
            <SecuritySelectButtons
                instruments={instruments}
                selectedInstrument={selectedInstrument}
                setSelectedInstrument={setSelectedInstrument}
                pledges={pledges}
            />
            <p className="text-center text-xs mt-6 mb-1 text-balance">
                The ASX sets an initial minimum buy-in of $500 for any company. The first time you buy shares in any
                company, you'll need to transfer in $500 + brokerage fees.
            </p>
            <p className="text-center text-xs mb-6">You own these shares outright until you sell them.</p>
            <div className="flex justify-center mt-8">
                <Button
                    onClick={() => selectedInstrument && onSelectionMade(selectedInstrument)}
                    disabled={!selectedInstrument}
                    variant="secondary"
                    size="lg"
                >
                    Continue
                </Button>
            </div>
        </div>
    )
}
