import { CampaignT } from '@/common/types'
import { CampaignStatusPill } from '../campaigns/CampaignStatusPill'
import { analytics } from '@/common/analytics/analytics.ts'
import { Link } from 'react-router-dom'

export type CampaignWithSubscription = CampaignT & { isSubscribed?: boolean; pledged?: boolean }

type Props = {
    securityCode: string
    campaign: CampaignWithSubscription
}

export const ProfilePastCampaign = ({ securityCode, campaign }: Props) => {
    return (
        <div className="border rounded-xl border-slate-200 mb-4 hover:shadow-bottom-right transition-shadow duration-300">
            <div key={campaign.title} className="flex items-start">
                <Link
                    className="flex w-full"
                    to={`/campaigns/${campaign.campaignId}`}
                    aria-label={`View ${campaign.title} campaign`}
                    onClick={() => {
                        analytics.track('ethical_profile_previous_campaign_card_clicked', {
                            companyCode: securityCode,
                            campaignId: campaign.campaignId,
                            campaignTitle: campaign.title,
                        })
                    }}
                >
                    {campaign.heroImage && (
                        <div className="flex-shrink-0 w-1/3 mr-4 overflow-hidden">
                            <img
                                className="w-full h-full object-cover"
                                src={campaign.heroImage.url}
                                alt={campaign.heroImage.alt ?? campaign.title}
                            />
                        </div>
                    )}
                    <div className="flex-1 text-black text-sm font-normal font-['Inter'] pl-2 pr-4 py-4">
                        {campaign.status && (
                            <CampaignStatusPill status={campaign.status} campaignId={campaign.campaignId} />
                        )}
                        <p className="text-black text-xl font-semibold font-['Inter'] leading-7">{campaign.title}</p>
                        <p className="my-4">{campaign.description}</p>
                    </div>
                </Link>
            </div>
        </div>
    )
}
