import { Button } from '@/common/ui'
import { FC } from 'react'
type Props = {
    showModal: () => void
}
export const ContactSupport: FC<Props> = ({ showModal }) => {
    return (
        <div className="bg-accent h-36 flex flex-row items-center justify-between p-10 gap-4 rounded-lg">
            <div className="lg:text-3xl">Need to update your details?</div>
            <Button size="lg" onClick={showModal}>
                Contact support
            </Button>
        </div>
    )
}
