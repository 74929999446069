import React, { useState } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { resetPassword, type ResetPasswordOutput } from 'aws-amplify/auth'

import useAuthStore from '@/auth/authStore'
import ResetPassword from './resetPassword'
import { Button, H2, LabelInput } from '@/common/ui'
import { useTrackPasswordReset } from '@/user/userQueries'

const schema = yup
    .object({
        email: yup.string().email('Please enter valid email').required(),
    })
    .required()

type Props = {
    onReset?: () => void
    onSignInClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export default function ForgotPassword({ onReset, onSignInClick }: Props) {
    const [isRunning, setIsRunning] = useState(false)
    const [isResetPassword, setIsResetPassword] = useState(false)
    const setUsername = useAuthStore((state) => state.setUsername)
    const trackPasswordReset = useTrackPasswordReset()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    })

    const onSubmit = async (data: { email: string }) => {
        const { email } = data
        trackPasswordReset.mutate({ emailId: email })
        try {
            setIsRunning(true)
            setUsername(email)
            const output = await resetPassword({ username: email })
            setIsResetPassword(true)
            handleResetPasswordNextSteps(output)
        } catch (error: any) {
            toast.error(error.message)
        } finally {
            setIsRunning(false)
        }
    }
    function handleResetPasswordNextSteps(output: ResetPasswordOutput) {
        const { nextStep } = output
        switch (nextStep.resetPasswordStep) {
            case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
                toast.success(
                    "If there is an account with that email address, we've sent a link to reset your password",
                )
                // Collect the confirmation code from the user and pass to confirmResetPassword.
                break
            case 'DONE':
                console.log('Successfully reset password.')
                break
        }
    }

    if (isResetPassword) {
        return <ResetPassword onReset={onReset} onSignInClick={onSignInClick} />
    }

    return (
        <div>
            <H2 className="text-center">Reset Password</H2>
            <form noValidate className="flex flex-col space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <LabelInput
                    type="email"
                    {...register('email')}
                    id="email"
                    required
                    formNoValidate
                    label="Email"
                    error={errors.email?.message}
                />
                <div className="flex flex-wrap justify-between items-center pt-6">
                    <Button loading={isRunning} variant="secondary" size="lg" type="submit" className="">
                        Reset Password
                    </Button>
                    {onSignInClick && (
                        <Button type="button" variant="link" className="p-1 h-8" onClick={onSignInClick}>
                            Sign in
                        </Button>
                    )}
                </div>
            </form>
        </div>
    )
}
