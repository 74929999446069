import { analytics } from '@/common/analytics/analytics'
import { GoogleIcon } from '@/common/assets/svg'
import { Button } from '@/common/ui/button'
import { signInWithRedirect } from 'aws-amplify/auth'
import { FC } from 'react'
import { toast } from 'sonner'

type Props = {
    isTncAccepted?: boolean
    onClick: () => void
    buttonText?: string
}
export const SocialSignIn: FC<Props> = ({ isTncAccepted = true, onClick, buttonText = 'Continue with Google' }) => {
    const handleGoogleSignin = async () => {
        onClick()
        if (!isTncAccepted) return
        try {
            await signInWithRedirect({
                provider: 'Google',
            })
            analytics.track('User SignedIn', { method: 'Google' })
        } catch (err: any) {
            console.log(err)
            toast.error(err.message)
        }
    }

    return (
        <>
            <Button
                role="button"
                className="w-full rounded-xl bg-muted-light"
                variant="outline"
                innerClassName="gap-4"
                onClick={handleGoogleSignin}
            >
                <GoogleIcon />
                <span>{buttonText}</span>
            </Button>
        </>
    )
}
