import { Button, H1, Label } from '@/common/ui'
import { useState } from 'react'
import { useContactSupport } from '../userQueries'

export const ContactSupportPage = () => {
    const [message, setMessage] = useState('')
    const [isRunning, setIsRunning] = useState(false)
    const [requestSent, setRequestSent] = useState(false)
    const contactSupport = useContactSupport()

    const sendRequest = () => {
        setIsRunning(true)
        contactSupport.mutate(
            { message },
            {
                onSuccess: () => {
                    setRequestSent(true)
                },
                onSettled: () => {
                    setIsRunning(false)
                },
            },
        )
    }

    return !requestSent ? (
        <>
            <H1>Contact Support</H1>
            <div className="flex flex-col gap-4 max-w-[800px]">
                <Label className="font-bold">Your message</Label>
                <textarea
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Please provide details of your request"
                    className="h-40 border rounded-xl p-4"
                />
                <Button
                    variant="secondary"
                    size="lg"
                    className="w-44 justify-center"
                    onClick={sendRequest}
                    loading={isRunning}
                >
                    Send
                </Button>
            </div>
        </>
    ) : (
        <>
            <H1 className="mb-4">Thank you</H1>
            <p className="mb-20">Your request has been submitted. We'll be in touch with you shorty.</p>
        </>
    )
}
