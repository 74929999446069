import { ClockIcon } from 'lucide-react'
import moment from 'moment'

export const Deadline = ({ deadline, iconSize }: { deadline?: string; iconSize: number }) => {
    if (!deadline) return

    const today = moment()
    const deadlineMoment = moment(deadline)

    if (deadlineMoment.isBefore(today)) return

    const hoursLeft = deadlineMoment.diff(today, 'hours')
    const daysLeft = deadlineMoment.diff(today, 'days')
    const minutesLeft = deadlineMoment.diff(today, 'minutes')
    const secondsLeft = deadlineMoment.diff(today, 'seconds')

    function dynamicTime() {
        if (daysLeft > 7) {
            return (
                <span className="text-xs ml-1 text-zinc-600">
                    Ends on <b>{deadlineMoment.format('MMMM D, YYYY')}</b>
                </span>
            )
        } else if (daysLeft === 7) {
            return (
                <span className="text-xs ml-1 text-zinc-600">
                    Ends in <b>1</b> week
                </span>
            )
        } else if (daysLeft > 1) {
            return (
                <span className="text-xs ml-1 text-zinc-600">
                    Ends in <b>{daysLeft}</b> days
                </span>
            )
        } else if (daysLeft === 1) {
            return (
                <span className="text-xs ml-1 text-zinc-600">
                    Ends <b>tomorrow, {deadlineMoment.format('h a')}</b>
                </span>
            )
        } else if (hoursLeft > 1) {
            return (
                <span className="text-xs ml-1 text-zinc-600">
                    Ends in <b>{hoursLeft}</b> hours
                </span>
            )
        } else if (hoursLeft === 1) {
            return (
                <span className="text-xs ml-1 text-zinc-600">
                    Ends in <b>{hoursLeft}</b> hour
                </span>
            )
        } else if (minutesLeft > 1) {
            return (
                <span className="text-xs ml-1 text-zinc-600">
                    Ends in <b>{minutesLeft}</b> minutes
                </span>
            )
        } else if (minutesLeft === 1) {
            return (
                <span className="text-xs ml-1 text-zinc-600">
                    Ends in <b>{minutesLeft}</b> minute
                </span>
            )
        } else if (secondsLeft > 0) {
            return (
                <span className="text-xs ml-1 text-zinc-600">
                    Ends <b>soon</b>
                </span>
            )
        }
    }

    return (
        <div className="flex items-center">
            <ClockIcon size={iconSize} />
            <span className="text-xs ml-1 text-zinc-600">{dynamicTime()}</span>
        </div>
    )
}
