import { API_ROUTES } from '@/common/constants'
import { app } from '../common/api/apiClient'
import { AccountDetails, WithdrawRequest } from './types'

type PayIdResponse = {
    payId: string
    payIdName: string
    bankAccountName: string
    bankAccountNumber: string
    bsb: string
}

type WalletBalance = {
    availableBalance: number
    reservedBalance: number
    totalBalance: number
    settlemenetBalance: number
}

export type Transaction = {
    id: string
    datetime: string
    amount: number
    description: string
    runningBalance: number
}

type WalletTransactions = {
    transactions: Transaction[]
}

export async function getPayId(): Promise<PayIdResponse> {
    const res = await app.get<PayIdResponse>(API_ROUTES.payId)
    return res.data
}

export async function getBalance(): Promise<WalletBalance> {
    const res = await app.get<WalletBalance>(API_ROUTES.balance)
    return res.data
}

export async function getTransactions(): Promise<Transaction[]> {
    const res = await app.get<WalletTransactions>(API_ROUTES.transactions)
    return res.data.transactions
}

export async function getAccountDetails(): Promise<AccountDetails> {
    const res = await app.get<AccountDetails>(API_ROUTES.accountDetails)
    return res.data
}

export async function saveAccountDetails(accountDetails: AccountDetails): Promise<void> {
    const res = await app.put<AccountDetails>(API_ROUTES.accountDetails, accountDetails)
    console.log(res)
}

export async function saveWithdrawalRequest(withdrawRequest: WithdrawRequest): Promise<void> {
    const res = await app.post<WithdrawRequest>(API_ROUTES.withdraw, withdrawRequest)
    console.log(res)
}
