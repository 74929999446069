const PRECISION = 4

export enum MoneyRoundingStrategy {
    CEILING = 0,
    FLOOR = 1,
}

export default class Money {
    static ZERO = Money.of(0)

    constructor(
        private readonly amount: number,
        private readonly roundingStrategy: MoneyRoundingStrategy = MoneyRoundingStrategy.FLOOR,
    ) {
        this.amount = parseFloat(amount.toFixed(PRECISION))
    }

    static of(amount: number | null | undefined, roundingStrategy = MoneyRoundingStrategy.FLOOR): Money {
        if (amount === null || amount === undefined) {
            //@ts-ignore
            return new NullMoney()
        }
        return new Money(amount, roundingStrategy)
    }
    static ofString(amount: any) {
        if (typeof amount !== 'string') {
            throw new Error('Amount is not a string')
        }
        if (amount.trim() === '') {
            throw new Error('Amount is empty')
        }
        if (Number.isNaN(Number(amount))) {
            throw new Error('Amount is invalid string')
        }
        return new Money(Number(amount))
    }
    add(other: Money) {
        return new Money(this.amount + other.value)
    }
    subtract(other: Money) {
        return new Money(this.amount - other.value)
    }
    multiply(times: number) {
        return new Money(this.amount * times)
    }
    divide(times: number) {
        if (times === 0) throw new Error('Cannot divide by 0')
        return new Money(this.amount / times)
    }
    lessThan(other: Money) {
        return this.value < other.value
    }
    greaterThan(other: Money) {
        return this.value > other.value
    }
    get value() {
        return this.amount
    }

    valueWithPrecision(precision = 2) {
        return parseFloat(this.amount.toFixed(precision))
    }

    toString(precision = 2) {
        // Partial cents handling strategy
        const displayAmount =
            this.roundingStrategy === MoneyRoundingStrategy.FLOOR
                ? Math.floor(this.amount * 100) / 100
                : Math.ceil(this.amount * 100) / 100

        if (displayAmount < 0) {
            return `-$${(displayAmount * -1).toFixed(precision).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }
        return `$${displayAmount.toFixed(precision).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    }
}

export class NullMoney {
    add() {
        return new NullMoney()
    }
    subtract() {
        return new NullMoney()
    }
    multiply() {
        return new NullMoney()
    }
    divide() {
        return new NullMoney()
    }
    lessThan() {
        return false
    }
    greaterThan() {
        return false
    }
    get value() {
        return null
    }
    toString() {
        return `$ --`
    }
}
