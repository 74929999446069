import useUserStore from '@/user/userStore'
import Intercom, { show, hide, onHide } from '@intercom/messenger-js-sdk'
import { useEffect } from 'react'

type Props = {
    showWidget?: boolean
    onHideWidget?: () => void
}

export function IntercomWidget({ showWidget = false, onHideWidget }: Props) {
    const { user } = useUserStore()
    Intercom({
        app_id: 'o0uvgmtg',
        user_id: user?.userId,
        name: user?.firstName,
        email: user?.emailId,
    })
    onHide(() => onHideWidget && onHideWidget())
    useEffect(() => {
        showWidget ? show() : hide()
    }, [showWidget])
    return null
}
