import { USER_ID_TOKEN } from '@/common/constants'
import { ReactNode, useEffect, useState } from 'react'
import { toast } from 'sonner'
import { app } from './apiClient'
import { ErrorToast, ApiError } from '../ui/ErrorToast'
import { AxiosResponseHeaders } from 'axios'
import { signOut } from 'aws-amplify/auth'
import { Button, H1 } from '../ui'

export type AxiosInterceptorProviderProps = {
    readonly children: ReactNode
}

export function AxiosInterceptorProvider({ children }: AxiosInterceptorProviderProps) {
    const [showSessionExpiryScreen, setShowSessionExpiryScreen] = useState(false)

    useEffect(() => {
        const sstRequestInterceptor = app.interceptors.request.use(
            (config) => {
                config.headers['Authorization'] = `Bearer ${sessionStorage.getItem(USER_ID_TOKEN)}`
                return config
            },
            (error) => {
                return Promise.reject(error)
            },
        )

        const sstResponseInterceptor = app.interceptors.response.use(
            (response) => {
                return response
            },
            (error) => {
                const { response } = error
                const headers: AxiosResponseHeaders = response.headers

                if (response.status === 500) {
                    // Only show toasts for 500 errors for now
                    const error: ApiError = {
                        ...response.data.errors[0], // TODO: what to do incase of multiple errors ?
                        requestId: headers.get('X-Amzn-Requestid'),
                        traceId: headers.get('X-Amzn-Trace-Id'),
                    }
                    toast.custom((t) => <ErrorToast toastId={t} error={error} />)
                }
                if (response.status === 401) {
                    setShowSessionExpiryScreen(true)
                }

                return Promise.reject(error)
            },
        )

        return () => {
            app.interceptors.response.eject(sstRequestInterceptor)
            app.interceptors.response.eject(sstResponseInterceptor)
        }
    }, [])

    const handleSignIn = () => {
        signOut()
        setShowSessionExpiryScreen(false)
    }

    if (showSessionExpiryScreen) {
        return (
            <div className="grid h-screen place-content-center p-8 gap-8">
                <H1 className="text-center mb-0">Signed Out</H1>
                <p className="text-center text-balance">
                    For security, you have been signed out. Please sign in again.
                </p>
                <Button variant="secondary" size="lg" onClick={handleSignIn}>
                    Sign In
                </Button>
            </div>
        )
    }

    return <>{children}</>
}
