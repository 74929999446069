import * as yup from 'yup'
import { Helmet } from 'react-helmet'
import { APP } from '@/common/strings'
import { useNavigate } from 'react-router'
import { APP_ROUTES } from '@/common/constants'
import { Onboarding } from '@/onboarding/OnboardingPageTemplate'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useUpdateMobile } from '@/user/userQueries'
import { useEffect } from 'react'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/common/ui/select'
import { InputError } from '@/common/ui/InputError'
import { LabelInput } from '@/common/ui/LabelInput'
import { analytics } from '@/common/analytics/analytics'
import useUserStore from '@/user/userStore'

type MobileNumberForm = {
    countryCode: string
    mobile: string
}

const schema = yup
    .object({
        countryCode: yup.string().required('Please select your country code'),
        mobile: yup
            .string()
            .required('Please enter your mobile number')
            .test('is-valid-mobile', function (value) {
                const { countryCode } = this.parent
                if (!value) {
                    return this.createError({ message: 'Please enter your mobile number' })
                }

                const cleanedValue = value.replace(/\D/g, '').replace(/^0/, '')
                const australiaRegex = /^4\d{8}$/
                const newZealandRegex = /^2\d{7,8}$/

                if (countryCode === '+61') {
                    if (cleanedValue.length < 9) {
                        return this.createError({
                            message:
                                "You've entered fewer than 9 digits. Please review your mobile number and try again.",
                        })
                    } else if (!australiaRegex.test(cleanedValue)) {
                        return this.createError({
                            message: 'Please enter a valid Australian mobile number in this format: (+61) 4XX XXX XXX.',
                        })
                    }
                } else if (countryCode === '+64') {
                    if (cleanedValue.length < 8) {
                        return this.createError({
                            message:
                                "You've entered fewer than 8 digits. Please review your mobile number and try again.",
                        })
                    } else if (!newZealandRegex.test(cleanedValue)) {
                        return this.createError({
                            message: 'Please enter a valid New Zealand mobile number in this format: (+64) 2X XXX XXXX',
                        })
                    }
                } else {
                    return this.createError({ message: 'Unsupported country code' })
                }
                return true
            }),
    })
    .required()

function formatMobileNumber(value: string, countryCode: string) {
    const cleanedValue = value.replace(/\D/g, '').replace(/^0/, '')

    if (countryCode === '+61') {
        if (cleanedValue.length <= 3) {
            return cleanedValue
        } else if (cleanedValue.length <= 6) {
            return cleanedValue.replace(/^(\d{3})(\d{1,3})$/, '$1 $2')
        } else if (cleanedValue.length <= 10) {
            return cleanedValue.replace(/^(\d{3})(\d{3})(\d{1,3})$/, '$1 $2 $3')
        }
    } else if (countryCode === '+64') {
        // 7 to 9 digits, usually 8
        if (cleanedValue.length <= 2) {
            return cleanedValue
        } else if (cleanedValue.length <= 5) {
            return cleanedValue.replace(/^(\d{2})(\d{1,3})$/, '$1 $2')
        } else if (cleanedValue.length <= 9) {
            return cleanedValue.replace(/^(\d{2})(\d{3})(\d{1,4})$/, '$1 $2 $3')
        }
    }
    return cleanedValue
}

function canValidate(length: number, countryCode: string) {
    if (countryCode === '+61') {
        return length >= 11
    }
    return length >= 10
}

export function MobileNumber() {
    const navigate = useNavigate()
    const sendMobileOtp = useUpdateMobile()
    const { user, setUser } = useUserStore()

    const {
        handleSubmit,
        control,
        setValue,
        trigger,
        register,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    })

    useEffect(() => {
        setValue('countryCode', '+61')
    }, [setValue])

    const countryCode = watch('countryCode', '+61')
    const mobile = watch('mobile', '')

    const onSubmit = (data: MobileNumberForm) => {
        const mobile = `${data.countryCode}${data.mobile}`
        if (!user) return
        setUser({ ...user, mobile })
        sendMobileOtp.mutate(mobile)
        analytics.track('onboarding_mobile_number_submitted')
    }

    useEffect(() => {
        if (!sendMobileOtp.isSuccess) return
        analytics.track('Mobile Updated')
        navigate(APP_ROUTES.MOBILE_VERIFICATION)
    }, [sendMobileOtp.isSuccess, navigate])

    const handleBack = () => {
        navigate(APP_ROUTES.ADDRESS)
    }

    const MAX_LENGTH = 10

    return (
        <>
            <Helmet>
                <title>Mobile Number | {APP.title}</title>
            </Helmet>
            <Onboarding
                title="Your Mobile Number"
                cta="Next: Verify Mobile"
                isLoading={sendMobileOtp.isPending}
                onBack={handleBack}
                // @ts-ignore
                onSubmit={handleSubmit(onSubmit)}
            >
                <p className="text-center text-balance mb-12">
                    Your mobile number will be used to authenticate your login and&nbsp;transactions.
                </p>
                <div className="flex flex-col w-full">
                    <div className="flex">
                        <Controller
                            name="countryCode"
                            control={control}
                            render={({ field }) => {
                                const onChange = (value: string) => {
                                    field.onChange()
                                    setValue('countryCode', value)
                                    trigger('countryCode')
                                }
                                return (
                                    <Select defaultValue="+61" onValueChange={onChange}>
                                        <SelectTrigger
                                            id={'countryCode'}
                                            className="w-[120px] rounded-l-full border-gray h-10 focus:ring-0"
                                        >
                                            <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent {...field}>
                                            <SelectGroup>
                                                <SelectItem value="+61">🇦🇺 +61</SelectItem>
                                                <SelectItem value="+64">🇳🇿 +64</SelectItem>
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                )
                            }}
                        />
                        <LabelInput
                            {...register('mobile')}
                            className="w-full rounded-l-[0px] rounded-r-full border border-l-0 border-gray py-2 px-4 h-10"
                            type="text"
                            id="mobile"
                            required
                            formNoValidate
                            placeholder={countryCode === '+61' ? '400 000 000' : '20 000 0000'}
                            inputMode="numeric"
                            maxLength={MAX_LENGTH + 1}
                            value={formatMobileNumber(mobile, countryCode)}
                            onChange={(e) => {
                                setValue('mobile', e.target.value, {
                                    shouldValidate: canValidate(e.target.value.length, countryCode),
                                })
                            }}
                            onBlur={() => {
                                trigger('mobile')
                            }}
                        />
                    </div>
                    <InputError message={errors.mobile?.message} />
                    <InputError message={errors.countryCode?.message} />
                </div>
            </Onboarding>
        </>
    )
}
