import { TRADING } from '@/common/strings'
import { Label } from '@/common/ui'
import { InfoIconTooltip } from '@/common/ui/tooltip'

export const OrderTypeLabel = () => (
    <div className="flex items-center gap-2 mb-1">
        <Label htmlFor="order-type" className="mb-0">
            Order type:
        </Label>
        <InfoIconTooltip>
            <p className="mb-2 font-bold">ORDER TYPES</p>
            <p className="mb-2">
                <span className="font-bold">Market - </span> {TRADING.marketOrderNote}
            </p>
            <p className="mb-2">
                <span className="font-bold">Limit - </span> {TRADING.limitOrderNote}
            </p>
        </InfoIconTooltip>
    </div>
)
