import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { resendSignUpCode, signIn } from 'aws-amplify/auth'

import { LabelInput } from '@/common/ui/LabelInput'
import { yupResolver } from '@hookform/resolvers/yup'
import { type LoginForm as LoginFormT } from '@/common/types'
import { Button } from '@/common/ui/button'
import { SocialSignIn } from './socialSignIn'
import { toast } from 'sonner'
import useAuthStore from '@/auth/authStore'
import { useState } from 'react'
import { PasswordInput } from '@/common/ui/PasswordInput'
import { OrDivider } from './OrDivider'
import { analytics } from '@/common/analytics/analytics'
import { useNavigate } from 'react-router'
import { APP_ROUTES } from '@/common/constants'
import { createSearchParams } from 'react-router-dom'

const schema = yup
    .object({
        email: yup.string().email('Please enter a valid email address').required(),
        password: yup
            .string()
            .min(6, 'Password should be at least 6 characters long')
            .max(50, 'Password can not be longer than 50 characters')
            .required(),
    })
    .required()

export function LoginTab() {
    const setIsForgotPassword = useAuthStore((state) => state.setIsForgotPassword)
    const [isRunning, setIsRunning] = useState(false)
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur',
    })

    const onSubmit = async (data: LoginFormT) => {
        const { email, password } = data
        try {
            setIsRunning(true)
            const result = await signIn({ username: email, password })
            if (result.isSignedIn) {
                analytics.track('User SignedIn', { method: 'Password' })
                return
            }
            if (result.nextStep.signInStep === 'CONFIRM_SIGN_UP') {
                await resendSignUpCode({ username: email })
                navigate({
                    pathname: APP_ROUTES.CONFIRM_USER,
                    search: createSearchParams({ username: email }).toString(),
                })
            }
        } catch (error: any) {
            console.log('error signing in', error)
            toast.error(error.message)
        } finally {
            setIsRunning(false)
        }
    }

    const handleForgotPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setIsForgotPassword(true)
    }

    return (
        <>
            <SocialSignIn onClick={() => {}} />
            <OrDivider />
            <form noValidate className="flex flex-col space-y-3" onSubmit={handleSubmit(onSubmit)}>
                <LabelInput
                    type="email"
                    {...register('email')}
                    id="email"
                    required
                    formNoValidate
                    label="Email"
                    error={errors.email?.message}
                />
                <PasswordInput
                    required
                    error={errors.password?.message}
                    {...register('password')}
                    id="password"
                    label="Password"
                />
                <div className="flex flex-wrap justify-between items-center pt-4 gap-4">
                    <Button loading={isRunning} variant="secondary" type="submit" className="">
                        Login to SIX
                    </Button>
                    <Button variant="ghost" onClick={handleForgotPassword}>
                        Forgot password?
                    </Button>
                </div>
            </form>
        </>
    )
}
