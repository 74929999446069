import { ENV } from './env'

export const TOP_COMPANIES_LIST_PAGE_SIZE = 20

export const FEATURES = {
    limitOrder: true,
}

export const SIGN_UP_LINKS = {
    termsAndConditions:
        'https://assets-global.website-files.com/659642fe2d32d0e5e8e622ae/65af5da8fc8917fbb7ea7061_Sustainable%20Investment%20Exchange%20-%20Terms%20and%20Conditions.pdf',
    privacyPolicy:
        'https://assets-global.website-files.com/659642fe2d32d0e5e8e622ae/65af5da8dee707424670557d_Sustainable%20Investment%20Exchange%20-%20Privacy%20Policy.pdf',
    finclearTermsOfTrade:
        'https://finclear.com.au/wp-content/uploads/2024/02/FinEx-Terms-of-Trade-FinClear-Services-as-Clearer-1.pdf',
}

export const FRANKIEONE_API = {
    kycUi:
        ENV === 'prod'
            ? 'https://backend.kycaml.frankiefinancial.io'
            : 'https://backend.kycaml.uat.frankiefinancial.io',
    script: 'https://assets.frankiefinancial.io/onboarding/v4/ff-onboarding-widget.umd.min.js',
}
