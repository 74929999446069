import { Sheet, SheetContent, SheetHeader } from '../ui/sheet'
import { AppNav } from './AppNav'
import { useEffect, useState } from 'react'
import { Menu } from 'lucide-react'

export const TopNavMobile = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    useEffect(() => {
        const resizeHandler = () => {
            if (window.innerWidth >= 768) {
                setIsMenuOpen(false)
            }
        }
        window.addEventListener('resize', resizeHandler)

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    const closeMenu = () => {
        setIsMenuOpen(false)
    }

    return (
        <div className="lg:hidden p-2">
            <Sheet open={isMenuOpen}>
                <button onClick={() => setIsMenuOpen(true)}>
                    <Menu size={26} className="text-primary" />
                </button>
                <SheetContent onClose={closeMenu} className="w-full">
                    <SheetHeader>
                        <AppNav onItemClick={closeMenu} />
                    </SheetHeader>
                </SheetContent>
            </Sheet>
        </div>
    )
}
