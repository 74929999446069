import { Helmet } from 'react-helmet'

import { FullScreenLoader } from '@/common/ui/FullScreenLoader'
import { APP } from '@/common/strings'
import { useFeatureFlags } from '@/common/featureFlags/featureFlags'
import { useFetchSecuritiesByTag, useFetchTrendingSecurities } from './tradingQueries'
import { SecurityCard } from '@/common/ui/SecurityCard'
import { H1 } from '@/common/ui'
import { useEffect, useState } from 'react'
import { cn } from '@/common/utils/utils'
import { analytics } from '@/common/analytics/analytics'
import { Link } from 'react-router-dom'
import { APP_ROUTES } from '@/common/constants.ts'

function TradingFilters({ filters, onFilter, selectedFilter }: any) {
    return (
        <div
            className="overflow-x-auto overflow-hidden pb-4 bg-white justify-start items-center gap-4 inline-flex"
            onScroll={() => {
                analytics.track('TradingFilters Scrolled')
            }}
        >
            {filters.map((filter: any) => (
                <button
                    onClick={() => {
                        onFilter(filter)
                        analytics.track('TradingFilter Selected', { filter: filter.name })
                    }}
                    className={cn(
                        'py-2 min-w-fit flex-col justify-center items-center gap-2 inline-flex border-b-2 border-transparent',
                        {
                            'border-b-2 border-black': filter.name === selectedFilter.name,
                            'hover:border-b-2 hover:border-stone-200': filter.name !== selectedFilter.name,
                        },
                    )}
                >
                    <div className="text-black text-2xl font-normal font-['Inter'] leading-relaxed">{filter.emoji}</div>
                    <div className="text-black text-xs font-medium font-['Inter'] leading-none">{filter.name}</div>
                </button>
            ))}
        </div>
    )
}

function Disclaimer({ filter }: { filter: string }) {
    const getText = () => {
        switch (filter) {
            case 'Trending ETFs':
                return 'The companies listed below were selected by SIX based on being the largest sustainability Exchange-Traded Funds (ETFs) on the Australian Stock Exchange (ASX) and are not a recommendation to buy or sell these shares. Please ensure you conduct your own research before making any investment decisions.'
            case 'Renewable energy':
                return 'These are shares that are (or hold) companies that earn revenue through renewable energy products or projects'
            case 'Energy transition minerals':
                return 'These are shares that are (or hold) companies that earn revenue through mining or refining materials that are necessary for producing renewable energy and electric vehicles'
            case 'Electric vehicles':
                return 'These are shares that are (or hold) companies that earn revenue through manufacturing electric vehicles'
            case 'Water efficiency':
                return 'These are shares that are (or hold) companies that earn revenue through products or services related to waste water'
            case 'Nutritious foods':
                return 'These are shares that are (or hold) companies that earn revenue through selling or producing nutritious food'
            case 'Recycling and waste management':
                return 'These are shares that are (or hold) companies that earn revenue through recycling or waste management products or services'
            case 'Healthcare':
                return 'These are shares that are (or hold) companies that earn revenue through providing healthcare products or services'
            case 'Education':
                return 'These are shares that are (or hold) companies that earn revenue through providing education products or services'
            case 'Sustainable buildings':
                return 'These are shares that are (or hold) securities that earn revenue through constructing or operating buildings with Green Star ratings'
            default:
                return ''
        }
    }

    return (
        <div className="self-stretch flex-col justify-start items-start gap-4 flex">
            <div className="self-stretch text-gray-700 text-sm font-normal font-['Inter'] leading-tight">
                {getText()}
            </div>
        </div>
    )
}

const filters = [
    { name: 'Trending ETFs', emoji: '🔥' },
    { name: 'Renewable energy', emoji: '🔋' },
    { name: 'Energy transition materials', emoji: '⛏️⚡️' },
    { name: 'Water efficiency', emoji: '💧' },
    { name: 'Nutritious foods', emoji: '🥕' },
    { name: 'Recycling and waste management', emoji: '♻️' },
    { name: 'Healthcare', emoji: '🏥' },
    { name: 'Electric vehicles', emoji: '🚗⚡️' },
    { name: 'Education', emoji: '🎓' },
    { name: 'Sustainable buildings', emoji: '🏡' },
]

export function Trading() {
    const [filter, setFilter] = useState(filters[0])
    const { showTradingPage } = useFeatureFlags()
    const { data: trendingSecurities, isLoading: isLoadingTrending } = useFetchTrendingSecurities()
    const [filteredSecurities, setFilteredSecurities] = useState(trendingSecurities)
    const { data: securitiesByTag, refetch: refetchSecuritiesByTag, isLoading } = useFetchSecuritiesByTag(filter.name)

    useEffect(() => {
        if (filter.name !== 'Trending ETFs') {
            refetchSecuritiesByTag()
        }
    }, [filter, refetchSecuritiesByTag])

    useEffect(() => {
        analytics.track('TradingFilterClicked ', {
            filter: filter.name,
        })
        if (filter.name === 'Trending ETFs') {
            setFilteredSecurities(trendingSecurities)
        } else {
            setFilteredSecurities(
                securitiesByTag?.filter(
                    (security: any) =>
                        security.securityDetails &&
                        security.securityDetails.priceInfo &&
                        !isNaN(security.changeInPriceYTD),
                ),
            )
        }
    }, [filter, trendingSecurities, securitiesByTag])

    // if (!trendingSecurities) return <FullScreenLoader />

    if (!showTradingPage) return null

    return (
        <div>
            <Helmet>
                <title>Trading | {APP.title}</title>
            </Helmet>
            {/* <NoTradingAccountAlert /> */}
            <H1>Trading</H1>
            <TradingFilters selectedFilter={filter} filters={filters} onFilter={(filter: any) => setFilter(filter)} />
            {isLoadingTrending || isLoading ? (
                <FullScreenLoader />
            ) : (
                <>
                    <div className="flex-col justify-center items-start gap-2 inline-flex">
                        <div className="flex-col justify-start items-start gap-4 flex">
                            <div className="text-black text-sm font-semibold font-['Inter'] leading-tight">
                                {filteredSecurities?.length} shares in {filter.name}
                            </div>
                            <Disclaimer filter={filter.name} />
                        </div>
                    </div>
                    <div className="grid mt-5 grid-wrap grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5">
                        {filteredSecurities?.map((security: any) => {
                            if (!security.securityDetails) return null
                            const price =
                                security.securityDetails.priceInfo.buyPrice ??
                                security.securityDetails.priceInfo.closingPrice
                            return (
                                <>
                                    <Link
                                        className="w-full text-current hover:underline"
                                        to={`${APP_ROUTES.SECURITY}/${security.securityDetails.securityCode}`}
                                        onClick={() =>
                                            analytics.track('TradingPageSecurityCard Clicked', {
                                                securityCode: security.securityDetails.securityCode,
                                                price:
                                                    security.securityDetails.priceInfo.buyPrice ??
                                                    security.securityDetails.priceInfo.closingPrice,
                                                changeInPriceYTD: security.changeInPriceYTD,
                                                numPositiveTags: security.ethicalTags.reduce(
                                                    (acc: number, curr: any) => (curr.isTagPositive ? acc + 1 : 0),
                                                    0,
                                                ),
                                                numNegativeTags: security.ethicalTags.reduce(
                                                    (acc: number, curr: any) => (curr.isTagPositive ? 0 : acc + 1),
                                                    0,
                                                ),
                                            })
                                        }
                                    >
                                        <SecurityCard
                                            loading={isLoading}
                                            securityCode={security.securityDetails.securityCode}
                                            securityName={
                                                security.securityDetails.companyName ??
                                                security.securityName ??
                                                security.securityDetails.securityName
                                            }
                                            price={price}
                                            changeInPriceYTD={security.changeInPriceYTD}
                                            historicalPrices={security.historicalPrices}
                                            ethicalTags={security.ethicalTags}
                                            withEthicalTags
                                        />
                                    </Link>
                                </>
                            )
                        })}
                    </div>
                </>
            )}
        </div>
    )
}
