import { DialogDescription, DialogHeader, DialogTitle } from '@/common/ui/dialog'
import { Table, TableBody, TableCell, TableRow } from '@/common/ui/table'
import { Check } from 'lucide-react'
import { AccountDetails } from './types'
import Money from '@/common/models/money'
import { Button } from '@/common/ui'

type Props = {
    accountDetails: AccountDetails
    amount: number
    onClose: () => void
}

export default function WithdrawAckDialog(props: Props) {
    const { accountDetails, amount } = props
    const currentTime = new Date()
    const timeString = currentTime.toLocaleTimeString('en-AU', { timeStyle: 'short', hour12: true })
    return (
        <DialogHeader className="text-center">
            <DialogTitle className="text-center text-3xl">
                <div className="p-2 flex flex-col gap-2 items-center">
                    <div className="rounded-full bg-secondary grid place-content-center w-12 h-12">
                        <Check size={24} />
                    </div>
                    <div className="py-2">Withdrawal Request Received</div>
                </div>
            </DialogTitle>
            <DialogDescription className="flex flex-col items-center">
                <p className="text-center max-w-[400px] text-balance mb-6">
                    Please allow 24-48 hours for funds to appear in your nominated bank account
                </p>
            </DialogDescription>
            <Table className="max-w-[460px] m-auto text-left">
                <TableBody>
                    <TableRow>
                        <TableCell>Amount</TableCell>
                        <TableCell className="font-bold text-right">{`${Money.of(amount)}`}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell className="font-bold text-right">
                            {currentTime.toLocaleDateString()}, {timeString}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Account Name</TableCell>
                        <TableCell className="font-bold text-right">{accountDetails.bankAccountName}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>BSB</TableCell>
                        <TableCell className="font-bold text-right">{accountDetails.bsb}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Account number</TableCell>
                        <TableCell className="font-bold text-right">{accountDetails.bankAccountNumber}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <div className="pt-6 w-full flex justify-center">
                <Button onClick={props.onClose} variant={'secondary'} type="button">
                    Close
                </Button>
            </div>
        </DialogHeader>
    )
}
