import { DataTable } from '@/common/ui/data-table'
import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { SecurityHead } from '@/securities/securityHead.tsx'

type holdingsProps = {
    pledges: { securityCode: string; securityName: string }[]
}

export function PledgedHoldings(props: holdingsProps) {
    const { pledges } = props

    const columns: ColumnDef<{ securityCode: string; securityName: string }>[] = useMemo(
        () => [
            {
                accessorKey: 'securityCode',
                enableSorting: false,
                header: () => {
                    return <span className="text-primary font-bold">Your Pledged Shares</span>
                },
                cell: ({ row }) => {
                    return (
                        <SecurityHead
                            nameClassName="max-w-20 md:max-w-40 xl:max-w-52 2xl:max-w-max"
                            security={{
                                securityCode: row.original.securityCode,
                                securityName: row.original.securityName,
                            }}
                        />
                    )
                },
            },
        ],
        [],
    )
    return (
        <DataTable
            columns={columns}
            data={pledges.length ? pledges : []}
            classNameHeader="px-0"
            classNameCell="px-0"
            noDataMessage="No shares held yet..."
        />
    )
}
