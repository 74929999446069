import { CampaignStatus } from '@/common/types'

export const CampaignStatusPill = ({ status, campaignId }: { status: CampaignStatus; campaignId: string }) => {
    return (
        <div>
            <style>{`.${campaignId} { --pill-col: ${status.pillColour}; }`}</style>
            <div className={`px-3 py-1 bg-[--pill-col] text-xs rounded-full inline-block mb-4 ${campaignId}`}>
                {status.label}
            </div>
        </div>
    )
}
