import { useEffect, useState } from 'react'
import { FieldErrors, UseFormRegister, UseFormTrigger, UseFormSetValue } from 'react-hook-form'
import Select from 'react-select/async'

import { debounce } from '@/common/utils/utils'
import { LabelInput } from '@/common/ui/LabelInput'
import { Address, AddressFormSchema, AddressType } from '@/common/types'
import { AddressDetails } from '@/common/types'
import { Checkbox } from '@/common/ui/checkbox'
import { searchAddress, searchAddressById } from '../userApi'

type Props = {
    address?: AddressDetails
    type: AddressType
    defaultValues?: AddressDetails
    register: UseFormRegister<AddressFormSchema>
    trigger: UseFormTrigger<AddressFormSchema>
    setValue: UseFormSetValue<AddressFormSchema>
    errors?: FieldErrors<AddressDetails>
}

export const SearchableAddressForm = ({ setValue, errors, type, trigger, register }: Props) => {
    const [addressId, setAddressId] = useState('')
    const [isManualEntry, setIsManualEntry] = useState(false)

    const fetchSearchResults = (inputValue: string, callback: (options: any[]) => void) => {
        if (inputValue.length < 4) return

        searchAddress(inputValue).then((res) => {
            const addresses = (res.addresses || []) as Address[]
            callback(
                addresses.map((val) => ({
                    value: val.id,
                    label: val.address,
                })),
            )
        })
    }

    useEffect(() => {
        if (!addressId) return
        searchAddressById(addressId).then((res) => {
            const address = res.address
            setValue(`${type}.addressPrefix`, address?.addressPrefix)
            trigger(`${type}.addressPrefix`)
            setValue(`${type}.streetNumber`, address?.streetNumber)
            trigger(`${type}.streetNumber`)
            setValue(`${type}.streetName`, `${address?.streetName}`)
            trigger(`${type}.streetName`)
            setValue(`${type}.streetType`, `${address?.streetType}`)
            trigger(`${type}.streetType`)
            setValue(`${type}.state`, address?.state)
            trigger(`${type}.state`)
            setValue(`${type}.country`, address?.country)
            trigger(`${type}.country`)
            setValue(`${type}.postCode`, address?.postCode)
            trigger(`${type}.postCode`)
            setValue(`${type}.city`, address?.suburb)
            trigger(`${type}.city`)
        })
    }, [addressId, setValue, trigger, type])

    const debouncedSearch = debounce(fetchSearchResults, 500)

    return (
        <>
            <Select
                className="mb-3 mt-1"
                styles={{
                    // @ts-ignore
                    control: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: '2rem',
                    }),
                }}
                onChange={(val) => {
                    setAddressId(val.value)
                }}
                placeholder="Search the address"
                loadOptions={debouncedSearch}
            />
            <div className="flex leading-none space-x-3 mb-2 items-center">
                <Checkbox
                    id="same-as-residential"
                    onCheckedChange={() => {
                        setIsManualEntry((isManual) => !isManual)
                    }}
                    checked={isManualEntry}
                />
                <label
                    htmlFor="same-as-residential"
                    className="text-xs leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                    Edit address manually
                </label>
            </div>
            {isManualEntry && (
                <div className="py-6">
                    <LabelInput
                        type="text"
                        {...register(`${type}.addressPrefix`)}
                        error={errors?.addressPrefix?.message}
                        placeholder="eg Unit 40, Level 2"
                        id="addressPrefix"
                        label="Address Prefix"
                        className="w-1/2"
                    />
                    <LabelInput
                        type="text"
                        {...register(`${type}.streetNumber`)}
                        error={errors?.streetNumber?.message}
                        id="street-number"
                        label="Street Number"
                        className="w-1/2"
                    />
                    <LabelInput
                        type="text"
                        {...register(`${type}.streetName`)}
                        error={errors?.streetName?.message}
                        id="street-name"
                        label="Street Name"
                    />
                    <LabelInput
                        type="text"
                        {...register(`${type}.streetType`)}
                        placeholder="eg AV, ST, RD"
                        error={errors?.streetType?.message}
                        id="street-type"
                        label="Street Type"
                        className="w-1/2"
                    />
                    <LabelInput
                        type="text"
                        {...register(`${type}.city`)}
                        error={errors?.city?.message}
                        placeholder=""
                        id="suburb"
                        label="Suburb"
                        className="w-1/2"
                    />
                    <LabelInput
                        type="text"
                        placeholder="eg NSW, VIC, QLD"
                        {...register(`${type}.state`)}
                        error={errors?.state?.message}
                        id="state"
                        label="State"
                        className="w-1/2"
                    />
                    <LabelInput
                        type="text"
                        placeholder=""
                        {...register(`${type}.postCode`)}
                        error={errors?.postCode?.message}
                        id="postal-code"
                        label="Postal Code"
                        className="w-1/2"
                    />
                    <LabelInput
                        type="text"
                        placeholder=""
                        {...register(`${type}.country`)}
                        error={errors?.country?.message}
                        id="country"
                        label="Country"
                    />
                </div>
            )}
        </>
    )
}
