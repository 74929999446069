import { CampaignT } from '@/common/types'
import { useEffect, useState } from 'react'
import { Campaign, CampaignWithSubscription } from './Campaign'
import { FullScreenLoader } from '../common/ui/FullScreenLoader'
import useTradeModalStore from '@/trading/tradeModalStore'
import useCampaignStore from '@/trading/campaignStore'
import { cn } from '@/common/utils/utils'
import { H3 } from '@/common/ui'

type Props = {
    campaignsList?: { campaigns: CampaignT[] }
    subscriptions?: { subscriptions: Record<string, boolean> }
}

export const CampaignsList = ({ campaignsList, subscriptions }: Props) => {
    const [campaigns, setCampaigns] = useState<CampaignWithSubscription[]>([])
    const { setSecurityCodes, setOrderAction } = useTradeModalStore()
    const { setCurrentCampaign } = useCampaignStore()

    useEffect(() => {
        if (!campaignsList) return
        campaignsList.campaigns.sort((a, b) => (b.displayOrder ?? 0) - (a.displayOrder ?? 0))
        const campaignsWithActions = campaignsList.campaigns.map((campaign) => {
            const isSubscribed = subscriptions?.subscriptions?.[campaign.campaignId] ?? false
            return { ...campaign, isSubscribed, pledged: false }
        })
        setCampaigns(campaignsWithActions)
    }, [campaignsList, subscriptions])

    if (!campaigns) {
        return <FullScreenLoader className="h-20" />
    }

    const spotlightCampaigns = campaigns.filter((campaign) => campaign.onSpotlight)
    const otherCampaigns = campaigns.filter((campaign) => !campaign.onSpotlight)

    return (
        <div>
            {spotlightCampaigns.length > 0 && (
                <>
                    <h3 className="text-[28px] py-2">In the spotlight</h3>
                    <div
                        className={cn(
                            'grid gap-4 grid-cols-1',
                            spotlightCampaigns.length > 1
                                ? 'lg:grid-cols-2 xl:grid-cols-2'
                                : 'lg:grid-cols-1 xl:grid-cols-1',
                        )}
                    >
                        {spotlightCampaigns.map((campaign) => (
                            <Campaign
                                key={campaign.campaignId}
                                layout={spotlightCampaigns.length > 1 ? 'default' : 'expanded'}
                                campaign={campaign}
                                handleOrder={() => {
                                    setSecurityCodes(campaign.targetCompaniesAsxCodes)
                                    setOrderAction('BUY')
                                    setCurrentCampaign(campaign)
                                }}
                            />
                        ))}
                    </div>
                </>
            )}
            {spotlightCampaigns.length > 0 && <H3 className="text-[28px] py-2">More campaigns</H3>}
            <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 ">
                {otherCampaigns.map((campaign) => (
                    <Campaign
                        key={campaign.campaignId}
                        campaign={campaign}
                        handleOrder={() => {
                            setSecurityCodes(campaign.targetCompaniesAsxCodes)
                            setOrderAction('BUY')
                            setCurrentCampaign(campaign)
                        }}
                    />
                ))}
            </div>
        </div>
    )
}
